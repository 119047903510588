import { computed, inject, Injectable } from "@angular/core";
import { IdentityStoreService } from "@cq/app/core/auth/services/identity-store.service";
import { Contextualizer } from "@cq/app/diagnostic/context";

@Injectable()
export class IdentityContextService implements Contextualizer {
  private identity = inject(IdentityStoreService);

  context = computed(() => {
    const id = this.identity.id();
    if (id) {
      return { user: id };
    } else {
      return undefined;
    }
  });
}
