import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { environment } from "@cq/environments/environment";
import { EventLogService } from "../event";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FullstoryService } from "../fullstory/fullstory.service";
import { Processor } from "./processor";

@Injectable()
export class FullstoryProcessorService extends Processor {
  isEnabled = signal(environment.fullstory.isEnabled);

  #destroyed = inject(DestroyRef);
  #fullstory = inject(FullstoryService);
  #log = inject(EventLogService);

  start(): void {
    this.#log
      .listen("fullstory")
      .pipe(takeUntilDestroyed(this.#destroyed))
      .subscribe((event) => this.#fullstory.dispatch(event));
  }
}
