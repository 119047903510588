import { inject, Injectable, InjectionToken } from "@angular/core";

export const SESSION_ID = new InjectionToken<string>("SESSION_ID", {
  factory: () => crypto.randomUUID(),
});

@Injectable({
  providedIn: "root",
})
export class SessionService {
  public readonly id = inject(SESSION_ID);
}
