import { StateService, TransitionOptions } from "@uirouter/core";
import StateManager from "./state-manager.service";

export interface ReviewAndQuoteOptions {
  initiateQuote?: boolean;
  reload?: boolean;
  allowDeactivation?: boolean;
}

export class LegacyReviewNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isReview() {
    return this.AEStateManager.current.isReview();
  }

  navigateToReviewAndQuote(
    accountNumber: string,
    options?: ReviewAndQuoteOptions,
  ) {
    const stateParamsData = {
      accountNumber,
      initiateQuote: options?.initiateQuote,
    };
    const transitionOptions: TransitionOptions = {};
    if (options?.reload) {
      transitionOptions.reload = true;
    }
    if (options?.allowDeactivation) {
      transitionOptions.custom = {
        allowDeactivation: true,
      };
    }
    return this.$state
      .go("ae.account.review.quote", stateParamsData, transitionOptions)
      .then(() => true);
  }
}
