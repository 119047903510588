import {
  Component,
  HostBinding,
  input,
  OnInit,
  inject,
  computed,
} from "@angular/core";
import {
  Notification,
  NotificationService,
} from "../../services/notification.service";
import { MessageModule } from "@cq/app/shared/message/message.module";
import { map, startWith, timer } from "rxjs";
import { IconModule } from "@cq/app/shared/icon/icon.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

const POLL_INCREMENT = 100;

@Component({
  selector: "cq-notification",
  standalone: true,
  imports: [
    CommonModule,
    IconModule,
    MessageModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: "./notification.component.html",
  styleUrl: "./notification.component.scss",
})
export class NotificationComponent implements OnInit {
  notification = input.required<Notification>();
  isInfo = computed(() => this.notification().level === "info");
  isWarning = computed(() => this.notification().level === "warning");
  message = computed(() => this.notification().message);

  elapsed = timer(0, POLL_INCREMENT).pipe(
    map((count) => {
      const notification = this.notification();
      const time = count * POLL_INCREMENT;
      const elapsed = (time / notification.duration) * 100;
      const remaining = 100 - elapsed;
      return Math.max(remaining, 0);
    }),
    startWith(100),
  );

  private readonly service = inject(NotificationService);

  ngOnInit(): void {
    const notification = this.notification();
    timer(notification.duration).subscribe(() =>
      this.service.clear(this.notification()),
    );
  }

  @HostBinding("class.level-info")
  private get _isInfo() {
    return this.isInfo();
  }

  @HostBinding("class.level-warning")
  private get _isWarning() {
    return this.isWarning();
  }

  dismiss() {
    this.service.clear(this.notification());
  }
}
