import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentInjector,
  inject,
  Injectable,
  InjectionToken,
  Injector,
  ProviderToken,
  Type,
} from "@angular/core";
import { Contextualizer, EventContextualizerService } from "./context";
import { FullstoryService } from "./fullstory/fullstory.service";
import { Processor } from "./processor";

export interface Configuration {
  contextualizers: Type<Contextualizer>[]; // contextualizers to be registered
  processors: Type<Processor>[]; // processors to be registered
}

export const DIAGNOSTIC_CONFIGURATION = new InjectionToken<Configuration>(
  "DIAGNOSTIC_CONFIGURATION",
);

@Injectable()
export class Diagnostics {
  #configuration = inject(DIAGNOSTIC_CONFIGURATION);
  #contextualizer = inject(EventContextualizerService);
  #injector = Injector.create({
    parent: inject(EnvironmentInjector),
    providers: [
      FullstoryService,
      ...this.#configuration.contextualizers,
      ...this.#configuration.processors,
    ],
  });

  initialize() {
    // Initialize Fullstory
    const fullstory = this.#injector.get(FullstoryService);
    if (fullstory.isEnabled()) {
      fullstory.init();
    }

    // register contextualizers
    this.#configuration.contextualizers
      .map((contextualizer) => this.#injector.get(contextualizer))
      .forEach((contextualizer) =>
        this.#contextualizer.register(contextualizer),
      );

    // start enabled processors
    this.#configuration.processors
      .map((processor) => this.#injector.get(processor))
      .filter((processor) => processor.isEnabled())
      .forEach((processor) => processor.start());
  }

  inject<T>(token: ProviderToken<T>) {
    return this.#injector.get(token);
  }
}

export function provideDiagnosticMonitoring(configuration: Configuration) {
  return [
    Diagnostics,
    { provide: DIAGNOSTIC_CONFIGURATION, useValue: configuration },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => inject(Diagnostics).initialize(),
    },
  ];
}
