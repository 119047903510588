import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[cq-tooltip-target]",
  exportAs: "tooltip-target",
  standalone: true,
})
export class TooltipTargetDirective {
  constructor(public readonly element: ElementRef) {}
}
