import { NgModule } from "@angular/core";
import { ButtonComponent } from "./components/button/button.component";
import { CheckboxButtonComponent } from "./components/checkbox-button/checkbox-button.component";
import { LinkButtonComponent } from "./components/link-button/link-button.component";

const exposed = [ButtonComponent, CheckboxButtonComponent, LinkButtonComponent];

@NgModule({
  imports: exposed,
  exports: exposed,
})
export class ButtonModule {}
