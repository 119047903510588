import { Injectable, signal } from "@angular/core";
import { PolicyPeriod } from "../models/policy";

@Injectable({
  providedIn: "root",
})
export class PolicyPeriodStoreService {
  #periods = signal<PolicyPeriod[]>([]);
  periods = this.#periods.asReadonly();

  record(period: PolicyPeriod) {
    this.#periods.update((current) => {
      // remove the associated period if it is already stored
      const filtered = current.filter(
        (p) => p.policyTransactionId !== period.policyTransactionId,
      );
      // add the provided period to the store
      return [...filtered, period];
    });
  }
}
