import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../models/app-config";
import { DeepPartial } from "ts-essentials";

@Injectable({ providedIn: "root" })
export class AppConfigRepositoryService {
  constructor(private readonly http: HttpClient) {}

  retrieve() {
    return this.http.get<AppConfig>("/config/app-config.json");
  }

  patch(_config: DeepPartial<AppConfig>) {
    throw new Error("Method not implemented.");
  }
}
