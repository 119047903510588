import { Inject, Injectable, InjectionToken } from "@angular/core";

export const LOCATION = new InjectionToken<Location>("LOCATION", {
  factory: () => window.location,
});

@Injectable({
  providedIn: "root",
})
export class EnvironmentService {
  constructor(@Inject(LOCATION) private readonly location: Location) {}

  get isExternal() {
    return (
      this.location.host.endsWith(".nationwide.com") ||
      this.location.host.endsWith(".external.clearquote.apps.nwie.net") || // temporary to allow for transition
      this.location.host.startsWith("external.")
    );
  }
}
