import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";

@Component({
  selector: "cq-dialog-content",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./dialog-content.component.html",
  styleUrls: ["./dialog-content.component.scss"],
})
export class DialogContentComponent {}
