import { inject, Injectable } from "@angular/core";
import { ConfigService } from "./config.service";

export class Feature {
  constructor(public readonly key: string) {}
}

export const FEATURES = Object.freeze({
  ENFORCE_ACCOUNT_INFORMATION_UPGRADE: new Feature(
    "enforce_account_information_upgrade",
  ),
});

@Injectable({
  providedIn: "root",
})
export class FeatureService {
  #config = inject(ConfigService);
  #features = new Map<string, boolean>();

  isEnabled(feature: Feature): boolean {
    return this.#features.get(feature.key) ?? this.#config.enabled(feature.key);
  }

  override(feature: Feature, enabled: boolean) {
    this.#features.set(feature.key, enabled);
  }
}
