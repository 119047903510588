import { inject, Injectable } from "@angular/core";
import { CQ_EDGE_PROVIDER } from "./edge-request.service";

interface EdgeOptions {
  unlock?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CommercialEdgeV3Service {
  #provider = inject(CQ_EDGE_PROVIDER);

  builder() {
    return this.#provider.edge();
  }

  jsonrpc<T, R>(
    capability: string,
    handler: string,
    method: string,
    data?: T,
    options?: EdgeOptions,
  ) {
    let request = this.builder()
      .capability(capability)
      .handler(handler)
      .method(method);

    if (options?.unlock) {
      request = request.unlock();
    }

    return request.jsonrpc<T, R>(data);
  }
}
