import { Injectable } from "@angular/core";
import {
  GetAgencyRespDTO,
  Handler,
  UserDTO,
  UserPermissionDTO,
} from "@cq/generated/edge/gateway/user.handler";
import { Observable } from "rxjs";
import { EdgeHandler } from "../edge-handler";

@Injectable({
  providedIn: "root",
})
export class GatewayUserService
  extends EdgeHandler
  implements Partial<Handler>
{
  capability = "gateway";
  handler = "user";

  getCurrentUser(): Observable<UserDTO> {
    return this.request().method("getCurrentUser").retry().jsonrpc();
  }

  getAvailableOrganizationCodesForCurrentUser(): Observable<GetAgencyRespDTO> {
    return this.request()
      .method("getAvailableOrganizationCodesForCurrentUser")
      .retry()
      .jsonrpc();
  }

  verifyPermissions(
    permissionDTO: UserPermissionDTO,
  ): Observable<UserPermissionDTO> {
    return this.request()
      .method("verifyPermissions")
      .retry()
      .jsonrpc(permissionDTO);
  }
}
