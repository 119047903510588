import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "cq-link-button",
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: "./link-button.component.html",
  styleUrls: ["./link-button.component.scss"],
})
export class LinkButtonComponent {}
