import { IsActiveMatchOptions } from "@angular/router";

export const EXACT: IsActiveMatchOptions = {
  paths: "exact",
  queryParams: "exact",
  fragment: "ignored",
  matrixParams: "ignored",
};

export const PARTIAL: IsActiveMatchOptions = {
  paths: "subset",
  queryParams: "ignored",
  fragment: "ignored",
  matrixParams: "ignored",
};
