import { NgModule } from "@angular/core";
import { AuthModule, StsConfigLoader } from "angular-auth-oidc-client";
import { AuthConfigService } from "./services/auth-config.service";

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (authConfigService: AuthConfigService) =>
          authConfigService.createConfigLoader(),
        deps: [AuthConfigService],
      },
    }),
  ],
  exports: [AuthModule],
  providers: [AuthConfigService],
})
export class AuthConfigModule {}
