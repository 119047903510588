import { Task } from "@cq/app/diagnostic";

export class ApigeeTask extends Task {
  constructor(method: string, url: string, messageId: string) {
    super("apigee", `${method} - ${url}`, {
      method,
      messageId,
      url,
    });
  }
}

export class EdgeTask extends Task {
  constructor(
    capability: string,
    handler: string,
    method: string,
    messageId: string,
  ) {
    super("edge", `${capability}:${handler} - ${method}`, {
      capability,
      handler,
      method,
      messageId,
    });
  }
}
