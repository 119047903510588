import { Injectable, computed, inject } from "@angular/core";
import { NavigationService } from "../navigation/services/navigation.service";
import { toObservable } from "@angular/core/rxjs-interop";
import { filter, take } from "rxjs";
import { ProgressService } from "../progress/services/progress.service";
import { LoaderStatusComponent } from "./loader-status/loader-status.component";
import { EventLogService } from "@cq/app/diagnostic";
import { ApplicationLoadEvent } from "../diagnostic";
import { WindowService } from "../system/services/window.service";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  #event = inject(EventLogService);
  #navigation = inject(NavigationService);
  #progress = inject(ProgressService);
  #window = inject(WindowService);

  loaded = computed(() => this.#navigation.hasCompleted());

  private loaded$ = toObservable(this.loaded);

  start() {
    this.#progress
      .track(
        this.loaded$.pipe(
          filter((loaded) => loaded),
          take(1),
        ),
        { component: LoaderStatusComponent },
      )
      .subscribe(() => {
        const origin = this.#window.origin();
        this.#event.log(new ApplicationLoadEvent(origin.time));
      });
  }
}
