import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  input,
} from "@angular/core";
import { TooltipService } from "@cq/app/shared/tooltip/services/tooltip.service";
import {
  autoPlacement,
  autoUpdate,
  computePosition,
  hide,
} from "@floating-ui/dom";
import { Subscription, filter, timer } from "rxjs";
import { TooltipTargetDirective } from "./directives/tooltip-target.directive";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({ template: "" })
export abstract class TooltipComponent implements OnInit, OnDestroy {
  target = input.required<ElementRef | HTMLElement | TooltipTargetDirective>();
  mouseover = input(false);
  focus = input(false);

  @ViewChild("tooltip") tooltip!: ElementRef;

  private dismiss?: Subscription;
  active = false;
  visible = false;
  style = {};

  private registeredEvents: { event: string; listener: () => unknown }[] = [];

  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  private cleanup = () => {};

  constructor(readonly tooltipService: TooltipService) {
    tooltipService.activated$
      .pipe(
        takeUntilDestroyed(),
        filter(() => this.active),
      )
      .subscribe((tooltip) => {
        if (tooltip !== this) {
          this.hide();
        }
      });
  }

  private compute(target: Element, tooltip: HTMLElement) {
    computePosition(target, tooltip, {
      middleware: [
        autoPlacement({
          alignment: "start",
          allowedPlacements: [
            "top-start",
            "top-end",
            "bottom-start",
            "bottom-end",
          ],
        }),
        hide(),
      ],
    }).then(({ middlewareData, x, y }) => {
      this.visible = !middlewareData.hide?.referenceHidden;
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  private initialize() {
    const target = this.targetElement;
    const tooltip = this.tooltip.nativeElement;
    this.cleanup();
    this.cleanup = autoUpdate(target, tooltip, () =>
      this.compute(target, tooltip),
    );
  }

  private get targetElement(): HTMLElement {
    const target = this.target();

    if (target instanceof TooltipTargetDirective) {
      return target.element.nativeElement;
    }

    if (target instanceof ElementRef) {
      return target.nativeElement;
    }

    return target;
  }

  ngOnInit(): void {
    this.registerEvent("mouseover", () => {
      if (this.mouseover()) {
        this.show();
      }
    });
    this.registerEvent("mouseout", () => this.hide());

    this.registerEvent("focusin", () => this.hide());
    this.registerEvent("focusout", () => {
      if (this.focus()) {
        this.flash();
      }
    });
  }

  ngOnDestroy(): void {
    for (const registered of this.registeredEvents) {
      this.targetElement.removeEventListener(
        registered.event,
        registered.listener,
      );
    }
    this.cleanup();
  }

  show() {
    this.dismiss?.unsubscribe();
    this.tooltipService.activated$.emit(this);
    this.initialize();
    this.active = true;
  }

  hide() {
    this.cleanup();
    this.active = false;
  }

  flash(duration = 3000) {
    this.show();
    this.dismiss = timer(duration).subscribe(() => this.hide());
  }

  private registerEvent(event: string, listener: () => unknown) {
    this.targetElement.addEventListener(event, listener);
    this.registeredEvents.push({ event, listener });
  }
}
