import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

type FontSet = "outlined" | "default";

@Component({
  selector: "cq-icon-font",
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: "./icon-font.component.html",
  styleUrls: ["./icon-font.component.scss"],
})
export class IconFontComponent {
  @Input({ required: true }) name!: string;
  @Input() fontSet: FontSet = "default";

  @HostBinding("class.cq-icon") readonly baseClass = true;
}
