import { environment } from "@cq/environments/environment";

class NoopConsole {
  log() {
    // noop
  }

  info() {
    // noop
  }

  warn() {
    // noop
  }

  error() {
    // noop
  }

  debug() {
    // noop
  }
}

export class Logger {
  private _$log: Partial<Console>;

  constructor(enable?: boolean) {
    this._$log = enable ? console : new NoopConsole();
  }

  initialize($log: Partial<Console>) {
    this._$log = $log;
  }

  log(...message: any[]) {
    if (this._$log && this._$log.log) {
      this._$log.log(...message);
    }
  }

  info(...message: any[]) {
    if (this._$log && this._$log.info) {
      this._$log.info(...message);
    }
  }

  warn(...message: any[]) {
    if (this._$log && this._$log.warn) {
      this._$log.warn(...message);
    }
  }

  error(...message: any[]) {
    if (this._$log && this._$log.error) {
      this._$log.error(...message);
    }
  }

  debug(...message: any[]) {
    if (this._$log && this._$log.debug) {
      this._$log.debug(...message);
    }
  }
}

export default new Logger(environment.isLoggingEnabled);
