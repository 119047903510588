<div
  #tooltip
  class="tooltip"
  role="tooltip"
  [class.active]="active"
  [class.visible]="visible"
  [style]="style"
>
  <div class="tooltip-content">
    <cq-icon-svg
      class="tooltip-icon error"
      name="alert-information"
    ></cq-icon-svg>
    <div class="tooltip-message"><ng-content></ng-content></div>
  </div>
</div>
