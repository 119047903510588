import { Injectable } from "@angular/core";
import { ConfigService } from "./services/config.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModeService {
  private readonly mode = toSignal(
    this.config.app$.pipe(map((config) => config.mode)),
    { initialValue: "prod" },
  );

  constructor(private readonly config: ConfigService) {}

  guard(callback: () => unknown) {
    if (!this.isProduction()) {
      callback();
    }
  }

  isProduction() {
    return this.mode() === undefined || this.mode() === "prod";
  }
}
