export class Task {
  readonly id = crypto.randomUUID();
  constructor(
    public readonly name: string,
    public readonly message: string,
    public readonly data?: object,
  ) {}

  serialize() {
    return { ...this.data, id: this.id, name: this.name };
  }
}
