import { Injectable, signal } from "@angular/core";

export type Level = "info" | "warning";

export class Notification {
  readonly id: string = crypto.randomUUID();

  constructor(
    readonly level: Level,
    readonly message: string,
    readonly duration: number,
  ) {}
}

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  notifications = signal<Notification[]>([]);

  notify(level: Level, message: string, duration = 10000) {
    this.push(new Notification(level, message, duration));
  }

  clear(notification: Notification) {
    this.notifications.update((notifications) => {
      const updated = [...notifications];
      const index = updated.indexOf(notification);
      if (index >= 0) {
        updated.splice(index, 1);
      }
      return updated;
    });
  }

  private push(notification: Notification) {
    this.notifications.update((notifications) => {
      const updated = [...notifications];
      updated.push(notification);
      return updated;
    });
  }
}
