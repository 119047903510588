import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogActionSpacerComponent } from "./components/dialog-action-spacer/dialog-action-spacer.component";
import { DialogActionsComponent } from "./components/dialog-actions/dialog-actions.component";
import { DialogContentComponent } from "./components/dialog-content/dialog-content.component";
import { DialogTitleActionsComponent } from "./components/dialog-title-actions/dialog-title-actions.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { DialogIconDirective } from "./directives/dialog-icon.directive";
import { DialogSubtitleDirective } from "./directives/dialog-subtitle.directive";
import { DialogTitleDirective } from "./directives/dialog-title.directive";

const exposed = [
  DialogComponent,
  DialogContentComponent,
  DialogActionSpacerComponent,
  DialogActionsComponent,
  DialogTitleActionsComponent,
  DialogIconDirective,
  DialogSubtitleDirective,
  DialogTitleDirective,
  MatDialogModule,
];

@NgModule({
  imports: exposed,
  exports: exposed,
})
export class DialogModule {}
