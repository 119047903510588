import { Injectable, inject } from "@angular/core";
import { ConfigService } from "../../environment/services/config.service";
import { EnvironmentService } from "../../environment/services/environment.service";
import { HttpParams } from "@angular/common/http";
import { ProgressService } from "../../progress/services/progress.service";
import { WindowService } from "../../system/services/window.service";
import { timer } from "rxjs";

/*
  It is not guaranteed (or even likely) that routing a user to PolicyCenter will see them
  land on the same PolicyCenter node that they've been communicating with for their ClearQuote
  session. PolicyCenter has an inherent delay when it comes to cross cluster data consistency,
  and so to promote a consistent user experience we must briefly wait before routing into PolicyCenter
  so that any last second data modifications are reflected when the user lands on the other side.
*/
const ROUTE_DELAY = 250;

@Injectable({
  providedIn: "root",
})
export class PolicyCenterRoutingService {
  private progress = inject(ProgressService);
  private window = inject(WindowService);

  constructor(
    private readonly config: ConfigService,
    private readonly environment: EnvironmentService,
  ) {}

  routeToDesktop() {
    const hostname = this.getPolicyCenterHostname();
    this.route(`https://${hostname}/pc/service/launch/DesktopActivities.do`);
  }

  routeToAccountFileSummary(accountNumber: string) {
    const hostname = this.getPolicyCenterHostname();
    const params = new HttpParams({
      fromObject: { AccountNumber: accountNumber },
    }).toString();
    this.route(
      `https://${hostname}/pc/service/launch/AccountFile.do?${params}`,
    );
  }

  routeToAccount(accountNumber?: string) {
    if (accountNumber) {
      // route to the account file summary if provided an account number
      this.routeToAccountFileSummary(accountNumber);
    } else {
      // otherwise route to the user desktop
      this.routeToDesktop();
    }
  }

  routeToPolicyGroupSummary(accountNumber: string, policyGroupNumber: string) {
    const hostname = this.getPolicyCenterHostname();
    const params = new HttpParams({
      fromObject: { accountNumber, policyGroupName: policyGroupNumber },
    }).toString();
    this.route(
      `https://${hostname}/pc/service/launch/PolicyGroupEntry_Ext.do?${params}`,
    );
  }

  routeToPolicyInfo(accountNumber: string, policyTransactionId: string) {
    const hostname = this.getPolicyCenterHostname();
    const params = new HttpParams({
      fromObject: {
        AccountNumber: accountNumber,
        JobNumber: policyTransactionId,
      },
    }).toString();
    this.route(`https://${hostname}/pc/service/launch/Job.do?${params}`);
  }

  private getPolicyCenterHostname() {
    return this.environment.isExternal
      ? this.config.app.policyCenter.external
      : this.config.app.policyCenter.internal;
  }

  private route(url: string) {
    this.progress.forever();
    timer(ROUTE_DELAY).subscribe(() => this.window.replace(url));
  }
}
