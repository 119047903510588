import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyAutoNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isAutoActive() {
    return this.AEStateManager.current.isAuto();
  }

  navigateToAutoInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.auto.auto-info", {
        jobNumber,
      })
      .then(() => true);
  }

  navigateToAutoCoverages(jobNumber: string) {
    return this.$state
      .go("ae.job.auto.auto-coverages", {
        jobNumber,
      })
      .then(() => true);
  }
}
