import { Injectable } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
import { GatewayUserService } from "../../edge/gateway/gateway-user.service";
import { Permission } from "../models/user";

import {
  PermissionsDTO,
  SystemPermissionType,
  UserPermissionDTO,
} from "@cq/generated/edge/gateway/user.handler";
import { PartialDeep } from "type-fest";

@Injectable({
  providedIn: "root",
})
export class UserPermissionRepositoryService {
  constructor(private readonly gatewayUser: GatewayUserService) {}

  verifyPermissions(
    systemPermissions: SystemPermissionType[],
  ): Observable<Permission[]> {
    const permissions: PartialDeep<PermissionsDTO>[] = systemPermissions.map(
      (permission) => {
        return { name: permission };
      },
    );

    const userPermission: PartialDeep<
      UserPermissionDTO,
      { recurseIntoArrays: true }
    > = {
      permissions: permissions,
    };

    // request user permission status from PolicyCenter
    return this.gatewayUser
      .verifyPermissions(userPermission as UserPermissionDTO)
      .pipe(
        map((userPermission) => {
          return userPermission.permissions.map((permission) => {
            return {
              available: permission.availableInd,
              name: permission.name,
            };
          });
        }),
        // deny all requested permissions in the event of an error
        catchError(() =>
          of<Permission[]>(
            systemPermissions.map((permission) => ({
              name: permission,
              available: false,
            })),
          ),
        ),
      );
  }
}
