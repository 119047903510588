import { inject, Injectable } from "@angular/core";
import { ConfigService } from "@cq/app/core/environment/services/config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, retry, shareReplay, switchMap, take, timer } from "rxjs";

const SPLUNK_ROOT =
  "https://http-inputs-nationwide.splunkcloud.com/services/collector";

export interface SplunkEvent<T> {
  source: string;
  sourcetype: string;
  index: string;
  host: string;
  time: number;
  event: T;
  fields: Record<string, string | undefined>;
}

export interface Response {
  code: number;
  text: string;
}

@Injectable({
  providedIn: "root",
})
export class SplunkHttpEventCollectorService {
  #config = inject(ConfigService);
  #http = inject(HttpClient);
  #headers$ = this.#config.app$.pipe(
    take(1),
    map(
      (config) =>
        new HttpHeaders({
          Authorization: `Splunk ${config.splunk.token}`,
        }),
    ),
    shareReplay(1),
  );

  event(events: SplunkEvent<unknown>[]) {
    return this.#headers$.pipe(
      switchMap((headers) =>
        this.#http
          .post<Response>(`${SPLUNK_ROOT}/event`, events, { headers })
          .pipe(
            retry({
              count: 3,
              delay: (_error, count) => timer(count * 250),
            }),
          ),
      ),
    );
  }
}
