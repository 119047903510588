import {
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, inject, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradeModule } from "@angular/upgrade/static";
import { OidcSecurityService } from "angular-auth-oidc-client";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { ConfigService } from "./core/environment/services/config.service";

import { AuthConfigModule } from "./core/auth/auth-config.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { legacyAccountNavigationProvider } from "./account/navigation/account-navigation-router.service";
import { ToolsModule } from "./tools/tools.module";
import { legacyAccountRetrieveProvider } from "./account/navigation/account-retrieve.service";
import { NotificationOverlayComponent } from "./core/notification/components/notification-overlay/notification-overlay.component";
import { NavigationService } from "./core/navigation/services/navigation.service";
import { LoaderService } from "./core/init/loader.service";
import { legacyUmbrellaNavigationProvider } from "./policy/products/umbrella/navigation/umbrella-navigation-router.service";
import { IdentityStoreService } from "./core/auth/services/identity-store.service";
import { SessionService } from "./core/environment/services/session.service";
import { EventLogService, provideDiagnosticMonitoring } from "./diagnostic";

import { FullstoryProcessorService } from "./diagnostic/processor/fullstory-processor.service";
import { LoggerProcessorService } from "./diagnostic/processor/logger-processor.service";
import { SplunkProcessorService } from "./diagnostic/processor/splunk-processor.service";
import { AccountContextService } from "./account/diagnostic";
import {
  IdentityContextService,
  PageContextService,
  SessionContextService,
  VisibilityContextService,
} from "./core/diagnostic";
import { PolicyTransactionContextService } from "./policy/diagnostic";
import { WindowService } from "./core/system/services/window.service";
import { legacyBusinessownersNavigationProvider } from "./policy/products/bop/navigation/bop-navigation-router.service";
import { legacyAutoNavigationProvider } from "./policy/products/auto/navigation/auto-navigation-router.service";
import { legacyGeneralLiabilityNavigationProvider } from "./policy/products/general-liability/navigation/general-liability-navigation-router.service";
import { legacyPropertyNavigationProvider } from "./policy/products/property/navigation/property-navigation-router.service";
import { legacyInlandMarineNavigationProvider } from "./policy/products/inland-marine/navigation/inland-marine-navigation-router.service";
import { legacyWorkersCompensationNavigationProvider } from "./policy/products/workers-compensation/navigation/workers-compensation-navigation-router.service";
import { legacyReviewNavigationProvider } from "./review/navigation/review-navigation-router.service";

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthConfigModule,
    BrowserModule,
    BrowserAnimationsModule,
    NotificationOverlayComponent,
    ToolsModule,
    UpgradeModule,
  ],
  providers: [
    // await application configuration
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.app$,
      deps: [ConfigService],
      multi: true,
    },
    // monitor navigation
    {
      provide: APP_INITIALIZER,
      useFactory: (navigation: NavigationService) => () => navigation.monitor(),
      deps: [NavigationService],
      multi: true,
    },
    // present loading message
    {
      provide: APP_INITIALIZER,
      useFactory: (loader: LoaderService) => () => loader.start(),
      deps: [LoaderService],
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: true,
        maxWidth: "unset",
        panelClass: "cq-dialog-panel",
        // https://github.com/angular/components/issues/28096
        autoFocus: "first-tabbable",
        delayFocusTrap: true,
        restoreFocus: true,
      },
    },
    provideDiagnosticMonitoring({
      contextualizers: [
        AccountContextService,
        IdentityContextService,
        PageContextService,
        PolicyTransactionContextService,
        SessionContextService,
        VisibilityContextService,
      ],
      processors: [
        FullstoryProcessorService,
        LoggerProcessorService,
        SplunkProcessorService,
      ],
    }),
    provideHttpClient(withInterceptorsFromDi()),
    // Legacy Providers
    legacyAccountNavigationProvider,
    legacyAccountRetrieveProvider,
    legacyAutoNavigationProvider,
    legacyBusinessownersNavigationProvider,
    legacyGeneralLiabilityNavigationProvider,
    legacyInlandMarineNavigationProvider,
    legacyPropertyNavigationProvider,
    legacyReviewNavigationProvider,
    legacyUmbrellaNavigationProvider,
    legacyWorkersCompensationNavigationProvider,
  ],
})
export class AppModule {
  public config = inject(ConfigService);
  public event = inject(EventLogService);
  public identity = inject(IdentityStoreService);
  public oidcSecurityService = inject(OidcSecurityService);
  public session = inject(SessionService);
  public upgrade = inject(UpgradeModule);
  public window = inject(WindowService);
}
