<cq-dialog>
  <cq-dialog-icon type="svg" name="exclamation-red"></cq-dialog-icon>
  <cq-dialog-title>Please Confirm</cq-dialog-title>
  <cq-dialog-subtitle *ngIf="data.message">
    {{ data.message }}
  </cq-dialog-subtitle>
  <cq-dialog-subtitle *ngIf="data.messageTemplate">
    <ng-container
      *ngTemplateOutlet="data.messageTemplate; context: data.messageContext"
    ></ng-container>
  </cq-dialog-subtitle>
  <cq-dialog-actions>
    <cq-button id="confirm-dialog-cancel" (click)="cancel()">
      <mat-icon fontIcon="cancel"></mat-icon>
      Cancel
    </cq-button>
    <cq-dialog-action-spacer></cq-dialog-action-spacer>
    <cq-button id="confirm-dialog-confirm" (click)="confirm()">
      <mat-icon fontIcon="check"></mat-icon>
      OK
    </cq-button>
  </cq-dialog-actions>
</cq-dialog>
