import { Context } from "../context";

export type Channel = "fullstory";
export type Priority = "standard" | "critical";

export class Event<T extends string = string> {
  constructor(
    public readonly type: T,
    public readonly message: string,
    public readonly data?: object,
    public readonly priority: Priority = "standard",
  ) {}

  serialize() {
    return { ...this.data, type: this.type };
  }
}

export class CriticalEvent<T extends string = string> extends Event<T> {
  constructor(
    public readonly type: T,
    public readonly message: string,
    public readonly data?: object,
  ) {
    super(type, message, data, "critical");
  }
}

export interface LoggedEvent {
  channels: Channel[];
  context: Context;
  event: Event;
  timestamp: number;
}
