import { computed, inject, Injectable } from "@angular/core";
import { Contextualizer } from "@cq/app/diagnostic/context";
import { ActivePolicyStoreService } from "../../stores/active-policy-store.service";

@Injectable()
export class PolicyTransactionContextService implements Contextualizer {
  private store = inject(ActivePolicyStoreService);

  context = computed(() => {
    const transactionId = this.store.policyTransactionId();
    if (transactionId) {
      return {
        policyTransactionId: transactionId,
      };
    } else {
      return undefined;
    }
  });
}
