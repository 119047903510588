<cq-dialog>
  <cq-dialog-icon type="svg" name="exclamation-red"></cq-dialog-icon>
  <cq-dialog-subtitle>
    <div class="message">{{ data.message }}</div>
    <div class="contact">
      Please try again later or contact the Help Desk at {{ helpDeskNumber() }}.
    </div>
  </cq-dialog-subtitle>
  <cq-dialog-actions>
    <cq-button class="exit" (click)="exit()">
      <cq-icon-font name="cancel"></cq-icon-font>
      Exit Quote
    </cq-button>
  </cq-dialog-actions>
</cq-dialog>
