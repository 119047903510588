interface Icons {
  solid: string;
  twoTone: string;
  modern: string;
}

export interface Product {
  code: ProductCode;
  icons: Icons;
  name: string;
}

function product(
  code: ProductCode,
  name: string,
  icons: Icons,
): Readonly<Product> {
  return Object.freeze({
    code,
    icons,
    name,
  });
}

export type ProductCode =
  | "bop"
  | "cop"
  | "wc"
  | "auto"
  | "prop"
  | "gl"
  | "farm"
  | "umb"
  | "im";

export const AUTO = product("auto", "Commercial Auto", {
  solid: "auto-black",
  twoTone: "auto-black-blue",
  modern: "products/auto",
});

export const BUSINESSOWNERS = product("bop", "Businessowners", {
  solid: "bop-black",
  twoTone: "bop-black-blue",
  modern: "products/bop",
});

export const COMMERCIAL_OUTPUT = product("cop", "Commercial Output", {
  solid: "cop-black",
  twoTone: "cop-black-blue",
  modern: "products/cop",
});

export const FARM = product("farm", "Farm", {
  solid: "farm-black",
  twoTone: "farm-black-blue",
  modern: "products/farm",
});

export const GENERAL_LIABILITY = product("gl", "General Liability", {
  solid: "general-liability-black",
  twoTone: "general-liability-black-blue",
  modern: "products/general-liability",
});

export const INLAND_MARINE = product("im", "Inland Marine", {
  solid: "inland-marine-black",
  twoTone: "inland-marine-blue-black",
  modern: "products/inland-marine",
});

export const PROPERTY = product("prop", "Property", {
  solid: "property-black",
  twoTone: "property-black-blue",
  modern: "products/property",
});

export const UMBRELLA = product("umb", "Umbrella", {
  solid: "umbrella-black",
  twoTone: "umbrella-blue-black",
  modern: "products/umbrella",
});

export const WORKERS_COMP = product("wc", "Workers' Compensation", {
  solid: "wc-black",
  twoTone: "wc-black-blue",
  modern: "products/wc",
});

export const PRODUCTS: Readonly<Product[]> = Object.freeze([
  BUSINESSOWNERS,
  AUTO,
  COMMERCIAL_OUTPUT,
  FARM,
  GENERAL_LIABILITY,
  INLAND_MARINE,
  PROPERTY,
  UMBRELLA,
  WORKERS_COMP,
]);

export const SMALL_MARKET_PRODUCTS: Readonly<Product[]> = Object.freeze([
  BUSINESSOWNERS,
  AUTO,
  GENERAL_LIABILITY,
  INLAND_MARINE,
  PROPERTY,
  UMBRELLA,
  WORKERS_COMP,
]);

export const MIDDLE_MARKET_PRODUCTS: Readonly<Product[]> = Object.freeze([
  AUTO,
  COMMERCIAL_OUTPUT,
  GENERAL_LIABILITY,
  INLAND_MARINE,
  PROPERTY,
  UMBRELLA,
  WORKERS_COMP,
]);
