import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TooltipComponent } from "@cq/app/shared/tooltip/tooltip.component";

@Component({
  selector: "cq-information-tooltip",
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: "./information-tooltip.component.html",
  styleUrls: ["../../tooltip.component.scss"],
})
export class InformationTooltipComponent extends TooltipComponent {}
