import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyGeneralLiabilityNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isGeneralLiabilityActive() {
    return this.AEStateManager.current.isGL();
  }

  navigateToGeneralLiabilityInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.gl.gl-info", {
        jobNumber,
      })
      .then(() => true);
  }

  navigateToGeneralLiabilityCoverages(jobNumber: string) {
    return this.$state
      .go("ae.job.gl.gl-coverages", {
        jobNumber,
      })
      .then(() => true);
  }
}
