import { CriticalEvent, Event } from "@cq/app/diagnostic/event";

export type ApplicationEventType =
  | "error"
  | "exit"
  | "init"
  | "load"
  | "visibility";

export class ApplicationEvent extends Event<ApplicationEventType> {}
export class CriticalApplicationEvent extends CriticalEvent<ApplicationEventType> {}

export class ApplicationErrorEvent extends CriticalApplicationEvent {
  constructor(message: string, error?: Error) {
    super("error", `Error - ${message}`, {
      message,
      error: {
        message: error?.message,
        // limit stack length to prevent overloading the splunk collector
        stack: error?.stack?.substring(0, 2000),
      },
    });
  }
}

export class ApplicationExitEvent extends CriticalApplicationEvent {
  constructor() {
    super("exit", "exiting");
  }
}

export class ApplicationInitEvent extends ApplicationEvent {
  constructor(origin: number, navigation?: { type: string; duration: number }) {
    const duration = Date.now() - origin;
    const elapsed = (duration / 1000).toFixed(3);
    super("init", `Application Initializing - ${elapsed}`, {
      duration,
      navigation,
    });
  }
}

export class ApplicationLoadEvent extends ApplicationEvent {
  constructor(origin: number) {
    const duration = Date.now() - origin;
    const elapsed = (duration / 1000).toFixed(3);
    super("load", `Application Loaded - ${elapsed}`, {
      duration,
    });
  }
}

export class ApplicationVisibilityEvent extends ApplicationEvent {
  constructor(visibility: string) {
    super("visibility", `Visibility - ${visibility}`, {
      visibility,
    });
  }
}
