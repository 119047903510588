import { Injectable } from "@angular/core";
import { LegacyAccountRetrieveService } from "@legacy/nationwide/js/src/edge/quoteandbind/account/account-retrieve.service";

export function legacyAccountRetrieveService(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyAccountRetrieveService>(
    "CQAccountRetrieveService",
  );
}

export const legacyAccountRetrieveProvider = {
  provide: LegacyAccountRetrieveService,
  useFactory: legacyAccountRetrieveService,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class AccountRetrieveService {
  constructor(
    private readonly legacyAccountRetrieve: LegacyAccountRetrieveService,
  ) {}

  retrieve(accountNumber: string) {
    return this.legacyAccountRetrieve.routeToAccount(accountNumber);
  }
}
