import { inject, Injectable, InjectionToken } from "@angular/core";
import { MessageIdService } from "../services/message-id.service";
import { SessionService } from "../../environment/services/session.service";
import { RequestService } from "../services/request.service";
import { EdgeRequestor } from "./requestor";
import { environment } from "@cq/environments/environment";

//cspell:disable
const APIGEE_EDGE_ROOT_PATH =
  "policy-management/commercial-edge/v3/policy-center/service/edgevgpa6";
const LOCAL_EDGE_ROOT_PATH = "pc/service/edgevgpa6";
//cspell:enable

export const CQ_EDGE_PROVIDER = new InjectionToken<EdgeRequestService>(
  "CQ_EDGE_PROVIDER",
  {
    factory: () =>
      environment.policycenter.isLocal
        ? new LocalEdgeRequestService()
        : new ApigeeEdgeRequestService(),
  },
);

interface EdgeRequestService {
  edge(): EdgeRequestor;
}

@Injectable()
export class ApigeeEdgeRequestService implements EdgeRequestService {
  #messageId = inject(MessageIdService);
  #session = inject(SessionService);
  #request = inject(RequestService);

  edge() {
    return new EdgeRequestor(
      this.#messageId,
      this.#session,
      this.#request.apigee(),
      {
        root: APIGEE_EDGE_ROOT_PATH,
        targeted: true,
      },
    );
  }
}

@Injectable()
export class LocalEdgeRequestService implements EdgeRequestService {
  #messageId = inject(MessageIdService);
  #session = inject(SessionService);
  #request = inject(RequestService);

  edge() {
    return new EdgeRequestor(
      this.#messageId,
      this.#session,
      this.#request.local(),
      {
        root: LOCAL_EDGE_ROOT_PATH,
        authentication: "identity",
        targeted: false,
      },
    );
  }
}
