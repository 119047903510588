import { Injectable } from "@angular/core";
import { shareReplay } from "rxjs";
import { AppConfigRepositoryService } from "./app-config.repository.service";
import { toSignal } from "@angular/core/rxjs-interop";

export class ConfigurationInitializationError extends Error {}

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  public readonly app$ = this.repository.retrieve().pipe(shareReplay(1));

  private _app = toSignal(this.app$);

  constructor(private readonly repository: AppConfigRepositoryService) {}

  get app() {
    const config = this._app();

    if (!config) {
      throw new ConfigurationInitializationError();
    }

    return config;
  }

  enabled(featureName: string): boolean {
    const config = this._app();

    if (!config || !config.feature) {
      return false;
    }

    const feature = config.feature[featureName];
    return feature === true || feature === "true";
  }
}
