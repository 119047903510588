import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyUmbrellaNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isUmbrellaActive() {
    return this.AEStateManager.current.isUmbrella();
  }

  navigateToUmbrellaInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.umb.umb-info", {
        jobNumber,
      })
      .then(() => true);
  }
}
