import { inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export abstract class Dialog<T, R, D = null> {
  readonly dialog: MatDialogRef<T, R> = inject(MatDialogRef);
  readonly data: D | null = inject(MAT_DIALOG_DATA, { optional: true });
}

export abstract class RequiredDataDialog<T, R, D> extends Dialog<T, R, D> {
  readonly data: D = inject(MAT_DIALOG_DATA);
}

export abstract class NoResultDialog<T> extends Dialog<T, undefined, null> {}
