import { inject, Injectable } from "@angular/core";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyGeneralLiabilityNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/gl/gl-navigation.service";

export function legacyGeneralLiabilityNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyGeneralLiabilityNavigationService>(
    "CQGeneralLiabilityNavigation",
  );
}

export const legacyGeneralLiabilityNavigationProvider = {
  provide: LegacyGeneralLiabilityNavigationService,
  useFactory: legacyGeneralLiabilityNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class GeneralLiabilityNavigationRouterService
  implements PolicyNavigationRouter
{
  #legacyGeneralLiabilityNavigation = inject(
    LegacyGeneralLiabilityNavigationService,
  );

  isGeneralLiabilityActive() {
    return this.#legacyGeneralLiabilityNavigation.isGeneralLiabilityActive();
  }

  navigateToGeneralLiabilityInformation(policyTransactionId: string) {
    return this.#legacyGeneralLiabilityNavigation.navigateToGeneralLiabilityInformation(
      policyTransactionId,
    );
  }

  navigateToGeneralLiabilityCoverages(policyTransactionId: string) {
    return this.#legacyGeneralLiabilityNavigation.navigateToGeneralLiabilityCoverages(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToGeneralLiabilityInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToGeneralLiabilityCoverages(policyTransactionId);
  }
}
