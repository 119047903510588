import { inject, Injectable } from "@angular/core";
import { LegacyReviewNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/common/navigation/review-navigation.service";

export function legacyReviewNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyReviewNavigationService>("CQReviewNavigation");
}

export const legacyReviewNavigationProvider = {
  provide: LegacyReviewNavigationService,
  useFactory: legacyReviewNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class ReviewNavigationRouterService {
  #legacyReviewNavigation = inject(LegacyReviewNavigationService);

  isReview() {
    return this.#legacyReviewNavigation.isReview();
  }

  navigateToReviewAndQuote(accountNumber?: string) {
    // do not attempt to navigate without a provided account number
    if (!accountNumber) {
      return Promise.resolve(false);
    }

    return this.#legacyReviewNavigation.navigateToReviewAndQuote(accountNumber);
  }
}
