import { inject, Injectable } from "@angular/core";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyInlandMarineNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/im/im-navigation.service";

export function legacyInlandMarineNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyInlandMarineNavigationService>(
    "CQInlandMarineNavigation",
  );
}

export const legacyInlandMarineNavigationProvider = {
  provide: LegacyInlandMarineNavigationService,
  useFactory: legacyInlandMarineNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class InlandMarineNavigationRouterService
  implements PolicyNavigationRouter
{
  #legacyPropertyNavigation = inject(LegacyInlandMarineNavigationService);

  isInlandMarineActive() {
    return this.#legacyPropertyNavigation.isInlandMarineActive();
  }

  navigateToInlandMarineInformation(policyTransactionId: string) {
    return this.#legacyPropertyNavigation.navigateToInlandMarineInformation(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToInlandMarineInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToInlandMarineInformation(policyTransactionId);
  }
}
