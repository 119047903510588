import { Component } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@Component({
  selector: "cq-loader-status",
  standalone: true,
  imports: [MatProgressSpinnerModule],
  templateUrl: "./loader-status.component.html",
  styleUrl: "./loader-status.component.scss",
})
export class LoaderStatusComponent {}
