import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { setAngularJSGlobal } from "@angular/upgrade/static";

import { AppModule } from "./app/app.module";

import { WalkMe } from "./app/core/init/walkme";
import { take } from "rxjs";
import { Identity } from "./app/core/auth/models/identity";

import angular from "angular";
import "angular-translate";
import "angular-i18n/angular-locale_en-us";
import { ApplicationInitEvent } from "./app/core/diagnostic/event";

setAngularJSGlobal(angular);

function navigateToStoredEndpoint() {
  const href = sessionStorage.getItem("cq.redirect");
  if (href != null) {
    sessionStorage.removeItem("cq.redirect");
    window.location.assign(href);
  }
}

function storeEndpoint() {
  sessionStorage.setItem("cq.redirect", window.location.href);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    const instance = ref.instance;
    instance.oidcSecurityService
      .checkAuth()
      .pipe(take(1)) // Not sure if this is necessary, the documentation doesn't indicate if this subscription completes immediately
      .subscribe((response) => {
        if (!response.isAuthenticated) {
          storeEndpoint();
          return instance.oidcSecurityService.authorize();
        }

        navigateToStoredEndpoint();

        // Establish user identity
        const identity = new Identity(response.userData);
        instance.identity.set(identity);

        WalkMe.init(instance.config.app.walkMeUrl);

        const origin = instance.window.origin();
        instance.event.log(
          new ApplicationInitEvent(origin.time, origin.navigation),
        );

        import("@legacy/nationwide/app/app").then(() => {
          instance.upgrade.bootstrap(
            document.documentElement,
            ["nationwide.quoteandbind.app"],
            {
              strictDi: true,
            },
          );
        });
      });
  })
  .catch((err) => console.error(err));
