<div class="dialog-title" mat-dialog-title>
  @if (icon) {
    <span class="dialog-title-icon">
      @if (icon.type === "font") {
        <mat-icon [fontIcon]="icon.name"></mat-icon>
      }
      @if (icon.type === "svg") {
        <cq-icon-svg [name]="icon.name"></cq-icon-svg>
      }
      @if (icon.type === "custom") {
        <ng-content select="cq-dialog-icon"></ng-content>
      }
    </span>
  }
  <span class="dialog-title-content">
    @if (title) {
      <h3 class="title"><ng-content select="cq-dialog-title"></ng-content></h3>
    }

    @if (subtitle) {
      <p class="subtitle">
        <ng-content select="cq-dialog-subtitle"></ng-content>
      </p>
    }
  </span>
  <span class="dialog-title-actions">
    <ng-content select="cq-dialog-title-actions"></ng-content>
  </span>
</div>
@if (content) {
  <div class="dialog-container">
    <div class="dialog-content" mat-dialog-content>
      <ng-content select="cq-dialog-content"></ng-content>
    </div>
  </div>
}
@if (actions) {
  <div class="dialog-actions" mat-dialog-actions>
    <ng-content select="cq-dialog-actions"></ng-content>
  </div>
}
