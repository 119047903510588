<mat-button-toggle
  #toggle
  [class.mat-elevation-z2]="!disabled"
  [class.tight-button]="appearance === 'tight'"
  [checked]="checked"
  [disabled]="disabled"
  [value]="value"
  (change)="checkedChange.emit(toggle.checked)"
>
  <span class="button-content">
    <div class="checkbox" [class.checked]="toggle.checked">
      @if (toggle.checked) {
        <mat-icon fontIcon="check"></mat-icon>
      }
    </div>
    <ng-content></ng-content>
  </span>
</mat-button-toggle>
