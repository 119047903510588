import { Injectable, inject } from "@angular/core";
import { UmbrellaNavigationService } from "./umbrella-navigation.service";
import { LegacyUmbrellaNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/umbrella/umbrella-navigation.service";
import { AccountStoreService } from "@cq/app/account/stores/account-store.service";
import {
  Page,
  PageUpgradeService,
} from "@cq/app/core/environment/services/page-upgrade.service";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";

export function legacyUmbrellaNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyUmbrellaNavigationService>("CQUmbrellaNavigation");
}

export const legacyUmbrellaNavigationProvider = {
  provide: LegacyUmbrellaNavigationService,
  useFactory: legacyUmbrellaNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class UmbrellaNavigationRouterService implements PolicyNavigationRouter {
  private readonly legacyUmbrellaNavigation = inject(
    LegacyUmbrellaNavigationService,
  );
  private readonly umbrellaNavigation = inject(UmbrellaNavigationService);
  private readonly accountStore = inject(AccountStoreService);
  private readonly pageUpgrade = inject(PageUpgradeService);

  isUmbrellaActive() {
    return (
      this.legacyUmbrellaNavigation.isUmbrellaActive() ||
      this.umbrellaNavigation.isUmbrellaActive()
    );
  }

  navigateToUmbrellaInformation(policyTransactionId: string) {
    const isFarmAccount = this.accountStore.account()?.type === "farm";

    if (
      !isFarmAccount &&
      this.pageUpgrade.isUpgraded(Page.UMBRELLA_INFORMATION)
    ) {
      return this.umbrellaNavigation.navigateToUmbrellaInformation();
    } else {
      return this.legacyUmbrellaNavigation.navigateToUmbrellaInformation(
        policyTransactionId,
      );
    }
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToUmbrellaInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToUmbrellaInformation(policyTransactionId);
  }
}
