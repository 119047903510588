import { DestroyRef, inject, Injectable, signal } from "@angular/core";
import { Processor } from "./processor";
import { EventLogService } from "../event";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { delay } from "rxjs";

@Injectable()
export class LoggerProcessorService extends Processor {
  #destroyed = inject(DestroyRef);
  #log = inject(EventLogService);

  isEnabled = signal(true);

  start() {
    this.#log.stream
      .pipe(
        // unsubscribe on destruction
        takeUntilDestroyed(this.#destroyed),
        // process events asynchronously
        delay(0),
      )
      // log the event message
      .subscribe((logged) =>
        console.debug(
          `[diag] ${logged.event.message}`,
          logged.event.serialize(),
        ),
      );
  }
}
