import { inject } from "@angular/core";
import { CommercialEdgeV3Service } from "../integration/commercial-edge-v3/commercial-edge-v3.service";

export abstract class EdgeHandler {
  private readonly edge = inject(CommercialEdgeV3Service);

  abstract capability: string;
  abstract handler: string;

  protected request() {
    return this.edge
      .builder()
      .capability(this.capability)
      .handler(this.handler);
  }
}
