import { inject, Injectable } from "@angular/core";
import { Channel, Event, LoggedEvent } from "./event";
import { filter, ReplaySubject } from "rxjs";
import { EventContextualizerService } from "../context";

@Injectable({
  providedIn: "root",
})
export class EventLogService {
  #contextualizer = inject(EventContextualizerService);
  #stream = new ReplaySubject<LoggedEvent>(100);
  stream = this.#stream.asObservable();

  log(event: Event, channels: Channel[] = []) {
    const logged: LoggedEvent = {
      channels,
      context: this.#contextualizer.context(),
      event,
      timestamp: Date.now(),
    };
    this.#stream.next(logged);
  }

  listen(channel: Channel) {
    return this.#stream.pipe(
      filter((event) => event.channels.includes(channel)),
    );
  }
}
