import { Environment } from "./environment.type";

export const environment: Environment = {
  fullstory: {
    isDevMode: false,
    isEnabled: true,
  },
  policycenter: {
    isLocal: false,
  },
  isLoggingEnabled: true,
  isSplunkEventCollectionEnabled: true,
  isWalkMeEnabled: true,
};
