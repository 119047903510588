<div class="notification">
  <div class="indicator">
    @if (isInfo()) {
      <cq-icon-font class="message-icon" name="info"></cq-icon-font>
    } @else if (isWarning()) {
      <cq-icon-font class="message-icon" name="warning"></cq-icon-font>
    }
  </div>
  <div class="message">{{ message() }}</div>
  <div class="duration">
    <mat-spinner
      class="spinner"
      mode="determinate"
      [diameter]="36"
      [strokeWidth]="4"
      [value]="elapsed | async"
    ></mat-spinner>
    <button
      class="dismiss"
      mat-icon-button
      aria-label="dismiss"
      (click)="dismiss()"
    >
      <mat-icon fontIcon="clear"></mat-icon>
    </button>
  </div>
</div>
