import { Injectable } from "@angular/core";
import { OidcSecurityService } from "angular-auth-oidc-client";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private readonly oidc: OidcSecurityService) {}

  logoff() {
    this.oidc.logoffLocal();
  }

  get accessToken$() {
    return this.oidc.getAccessToken();
  }

  get identityToken$() {
    return this.oidc.getIdToken();
  }
}
