import { ComponentType } from "@angular/cdk/portal";
import { Injectable, inject } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from "../components/confirm-dialog/confirm-dialog.component";
import { Dialog } from "../model/dialog";
import { ProgressService } from "@cq/app/core/progress/services/progress.service";

export type DialogConfiguration<D> = MatDialogConfig<D>;

type Return<T> = T extends Dialog<T, infer R, unknown> ? R : unknown;
type Data<T> = T extends Dialog<T, unknown, infer D> ? D : unknown;

@Injectable({
  providedIn: "root",
})
export class DialogService {
  private dialog = inject(MatDialog);
  private progress = inject(ProgressService);

  /**
   * Opens the given dialog
   */
  open<
    T extends Dialog<T, R, Partial<D>>,
    R extends Return<T>,
    D extends Data<T>,
  >(componentType: ComponentType<T>, config?: DialogConfiguration<D>) {
    return this.dialog.open<T, D, R>(componentType, config);
  }

  /**
   * Opens the given dialog and pauses the progress spinner
   */
  prompt<
    T extends Dialog<T, R, Partial<D>>,
    R extends Return<T>,
    D extends Data<T>,
  >(componentType: ComponentType<T>, config?: DialogConfiguration<D>) {
    return this.progress.pause(this.open(componentType, config).afterClosed());
  }

  /**
   * Opens a Confirmation dialog and pauses the progress spinner
   */
  confirm(options: ConfirmDialogData) {
    return this.prompt(ConfirmDialogComponent, { data: options });
  }
}
