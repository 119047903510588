import { computed, Injectable, signal } from "@angular/core";
import { Context, Contextualizer } from "./context";

@Injectable({
  providedIn: "root",
})
export class EventContextualizerService {
  private contexualizers = signal<Contextualizer[]>([]);

  context = computed(() => {
    const contexualizers = this.contexualizers();
    const initial: Context = {};
    return contexualizers
      .map((contexualizer) => contexualizer.context())
      .reduce<Context>(
        (accumulator, context) => ({ ...accumulator, ...context }),
        initial,
      );
  });

  register(contexualizer: Contextualizer) {
    this.contexualizers.update((contexualizers) => [
      ...contexualizers,
      contexualizer,
    ]);
  }
}
