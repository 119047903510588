import { computed, inject, Injectable } from "@angular/core";
import { AccountStoreService } from "@cq/app/account/stores/account-store.service";
import { Contextualizer } from "@cq/app/diagnostic/context";

@Injectable()
export class AccountContextService implements Contextualizer {
  private account = inject(AccountStoreService);

  context = computed(() => {
    const accountNumber = this.account.accountNumber();
    if (accountNumber) {
      return {
        // strip spaces from the account number to simplify contextual querying
        accountNumber: accountNumber.replace(/\s/g, ""),
      };
    } else {
      return undefined;
    }
  });
}
