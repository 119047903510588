import { Injectable, computed, signal } from "@angular/core";
import { INVALID_USER, User } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class UserStoreService {
  readonly current = signal<User>(INVALID_USER);
  readonly isExternal = computed(() => this.current().isExternal);
  readonly isInternal = computed(() => !this.isExternal());

  setCurrentUser(user: User) {
    this.current.set(user);
  }
}
