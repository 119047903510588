import { inject, Injectable } from "@angular/core";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyAutoNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/auto/auto-navigation.service";

export function legacyAutoNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyAutoNavigationService>("CQAutoNavigation");
}

export const legacyAutoNavigationProvider = {
  provide: LegacyAutoNavigationService,
  useFactory: legacyAutoNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class AutoNavigationRouterService implements PolicyNavigationRouter {
  #legacyAutoNavigation = inject(LegacyAutoNavigationService);

  isAutoActive() {
    return this.#legacyAutoNavigation.isAutoActive();
  }

  navigateToAutoInformation(policyTransactionId: string) {
    return this.#legacyAutoNavigation.navigateToAutoInformation(
      policyTransactionId,
    );
  }

  navigateToAutoCoverages(policyTransactionId: string) {
    return this.#legacyAutoNavigation.navigateToAutoCoverages(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToAutoInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToAutoCoverages(policyTransactionId);
  }
}
