import { computed, Injectable, signal } from "@angular/core";
import { PageComponent } from "../page-component";

@Injectable({
  providedIn: "root",
})
export class PageStoreService {
  private _current = signal<PageComponent | undefined>(undefined);
  current = this._current.asReadonly();
  currentId = computed(() => this.current()?.id);

  register(page: PageComponent) {
    this._current.set(page);
  }

  deregister(page: PageComponent) {
    if (this._current() === page) {
      this._current.set(undefined);
    }
  }
}
