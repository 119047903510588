import { inject, Injectable } from "@angular/core";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyBusinessownersNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/bop/bop-navigation.service";

export function legacyBusinessownersNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyBusinessownersNavigationService>(
    "CQBusinessownersNavigation",
  );
}

export const legacyBusinessownersNavigationProvider = {
  provide: LegacyBusinessownersNavigationService,
  useFactory: legacyBusinessownersNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class BopNavigationRouterService implements PolicyNavigationRouter {
  #legacyBusinessownersNavigation = inject(
    LegacyBusinessownersNavigationService,
  );

  isBusinessownersActive() {
    return this.#legacyBusinessownersNavigation.isBusinessownersActive();
  }

  navigateToBusinessownersInformation(policyTransactionId: string) {
    return this.#legacyBusinessownersNavigation.navigateToBusinessownersInformation(
      policyTransactionId,
    );
  }

  navigateToBusinessownersCoverages(policyTransactionId: string) {
    return this.#legacyBusinessownersNavigation.navigateToBusinessownersCoverages(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToBusinessownersInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToBusinessownersCoverages(policyTransactionId);
  }
}
