import { isEmpty } from "lodash";
import logger from "@clearquote/common/logger";
import { environment } from "@cq/environments/environment";

export class WalkMe {
  /*
    Generic WalkMe initialization to allow for provided configuration, fashioned after provided initialization scripts.

    (function () {
      var walkme = document.createElement("script");
      walkme.type = "text/javascript";
      walkme.async = true;
      walkme.src =
        "https://cdn.walkme.com/users/0b14fcbbcf5f428da7aea21f8f51d399/test/walkme_0b14fcbbcf5f428da7aea21f8f51d399_https.js";

      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(walkme, s);
      window._walkmeConfig = { smartLoad: true };
    })();
  */
  static init(url: string) {
    if (!environment.isWalkMeEnabled || isEmpty(url)) {
      logger.warn(
        "WalkMe integration disabled, or not configured. Bypassing initialization.",
      );
      return;
    }

    const walkme = document.createElement("script");
    walkme.type = "text/javascript";
    walkme.async = true;
    walkme.src = url;

    const s = document.getElementsByTagName("script")[0];
    s.parentNode?.insertBefore(walkme, s);
    window._walkmeConfig = { smartLoad: true };
  }
}
