import { CommonModule } from "@angular/common";
import { Component, ContentChild } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { IconModule } from "@cq/app/shared/icon/icon.module";
import { DialogIconDirective } from "../../directives/dialog-icon.directive";
import { DialogActionsComponent } from "../dialog-actions/dialog-actions.component";
import { DialogContentComponent } from "../dialog-content/dialog-content.component";
import { DialogSubtitleDirective } from "../../directives/dialog-subtitle.directive";
import { DialogTitleDirective } from "../../directives/dialog-title.directive";

@Component({
  selector: "cq-dialog",
  standalone: true,
  templateUrl: "./dialog.component.html",
  imports: [CommonModule, IconModule, MatDialogModule, MatIconModule],
  styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent {
  @ContentChild(DialogIconDirective) icon?: DialogIconDirective;
  @ContentChild(DialogTitleDirective) title?: DialogIconDirective;
  @ContentChild(DialogSubtitleDirective) subtitle?: DialogIconDirective;
  @ContentChild(DialogContentComponent) content?: DialogContentComponent;
  @ContentChild(DialogActionsComponent) actions?: DialogActionsComponent;
}
