import { NgModule } from "@angular/core";
import {
  RouterModule,
  Routes,
  UrlHandlingStrategy,
  UrlTree,
} from "@angular/router";
import { userGuard } from "./core/user/guards/user.guard";

const routes: Routes = [
  {
    path: "upgrade",
    canActivate: [userGuard],
    children: [
      {
        path: "account",
        loadChildren: () =>
          import("./account/account.module").then((m) => m.AccountModule),
      },
      {
        path: "policy-transaction",
        loadChildren: () =>
          import("./policy/policy.module").then((m) => m.PolicyModule),
      },
      {
        path: "mockup",
        loadChildren: () =>
          import("./mockup/mockup.module").then((m) => m.MockupModule),
      },
      {
        path: "review",
        loadChildren: () =>
          import("./review/review.module").then((m) => m.ReviewModule),
      },
    ],
  },
];

class UpgradeUrlHandlingStrategy implements UrlHandlingStrategy {
  shouldProcessUrl(url: UrlTree): boolean {
    return url.toString().startsWith("/upgrade");
  }
  extract(url: UrlTree): UrlTree {
    return url;
  }
  merge(newUrlPart: UrlTree): UrlTree {
    return newUrlPart;
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: UrlHandlingStrategy, useClass: UpgradeUrlHandlingStrategy },
  ],
})
export class AppRoutingModule {}
