import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyInlandMarineNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isInlandMarineActive() {
    return this.AEStateManager.current.isIm();
  }

  navigateToInlandMarineInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.im.im-info", {
        jobNumber,
      })
      .then(() => true);
  }
}
