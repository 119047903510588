import { inject, Injectable, signal } from "@angular/core";
import { SessionService } from "@cq/app/core/environment/services/session.service";
import { Contextualizer } from "@cq/app/diagnostic/context";

@Injectable()
export class SessionContextService implements Contextualizer {
  private session = inject(SessionService);

  context = signal({
    session: this.session.id,
  });
}
