import {
  StateObject,
  StateParams,
  StateService,
  TransitionOptions,
} from "@uirouter/core";
import StateManager, { STATES } from "./state-manager.service";

type Params = {
  [key: string]: any;
};

export interface AccountInformationNavigationOptions {
  openAddressModalOnLoad?: boolean;
  openNamedInsuredModalOnLoad?: boolean;
  params?: Params;
  reload?: boolean;
  reloadAccount?: boolean;
  allowDeactivation?: boolean;
}

export interface AccountQuestionsNavigationOptions {
  openFarmBureau?: boolean;
}

export interface AccountAddProductsOptions {
  reload?: boolean;
  reloadAccount?: boolean;
  allowDeactivation?: boolean;
}

export class LegacyAccountNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isAccountActive() {
    return this.AEStateManager.current.isAccount();
  }

  isAccountInformationActive() {
    return this.AEStateManager.current.isAccountInformation();
  }

  isAccountInformation(state: StateObject) {
    return STATES.accountInformation.includes(state.name);
  }

  isAccountQuestionsActive() {
    return this.AEStateManager.current.isAccountQuestions();
  }

  isAccountQuestions(state: StateObject) {
    return STATES.accountQuestions.includes(state.name);
  }

  isAccountAddProductsActive() {
    return this.AEStateManager.current.isAccountAddProduct();
  }

  navigateToAccountInformation(
    accountNumber?: string,
    options?: AccountInformationNavigationOptions,
  ): Promise<boolean> {
    const stateParamsData = {
      accountNumber,
      openAddressModalOnLoad: options?.openAddressModalOnLoad,
      openNamedInsuredModalOnLoad: options?.openNamedInsuredModalOnLoad,
    };
    const stateParams = options?.params ? options.params : stateParamsData;
    const transitionOptions: TransitionOptions = {};
    if (options?.reload) {
      transitionOptions.reload = true;
    }
    if (options?.reloadAccount) {
      transitionOptions.reload = "ae.account";
    }
    if (options?.allowDeactivation) {
      transitionOptions.custom = { allowDeactivation: true };
    }
    return this.$state
      .go("ae.account.information", stateParams, transitionOptions)
      .then(() => true);
  }

  navigateToAccountQuestions(
    accountNumber: string,
    options?: AccountQuestionsNavigationOptions,
  ) {
    const stateParams = new StateParams({
      accountNumber: accountNumber,
    });

    if (options?.openFarmBureau) {
      stateParams.expandContainer = "farm bureau";
    }

    return this.$state
      .go("ae.account.existing.questions", stateParams)
      .then(() => true);
  }

  navigateToAddProducts(
    accountNumber: string,
    options?: AccountAddProductsOptions,
  ) {
    const stateParamsData = {
      accountNumber,
    };
    const transitionOptions: TransitionOptions = {};
    if (options?.reload) {
      transitionOptions.reload = true;
    }
    if (options?.reloadAccount) {
      transitionOptions.reload = "ae.account";
    }
    if (options?.allowDeactivation) {
      transitionOptions.custom = { allowDeactivation: true };
    }
    return this.$state
      .go("ae.account.add-products", stateParamsData, transitionOptions)
      .then(() => true);
  }
}
