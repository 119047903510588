import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ConfigService } from "../../environment/services/config.service";
import { Requestor } from "./requestor";
import { RequestDecoratorService } from "./request-decorator.service";
import { ApigeeTask } from "../diagnostic/task";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  #config = inject(ConfigService);
  #decorator = inject(RequestDecoratorService);
  #http = inject(HttpClient);

  apigee() {
    return this.http(`https://${this.#config.app.apigeeHost}`)
      .oauth()
      .task(
        (target, identifier) =>
          new ApigeeTask(target.method, target.url, identifier),
      );
  }

  http(root: string) {
    return new Requestor(this.#decorator, this.#http, {
      root,
    });
  }

  local() {
    return this.http("");
  }
}
