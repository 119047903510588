<div
  #tooltip
  class="tooltip"
  role="tooltip"
  [class.active]="active"
  [class.visible]="visible"
  [style]="style"
>
  <div class="tooltip-content">
    <mat-icon class="tooltip-icon info" fontIcon="info"></mat-icon>
    <div class="tooltip-message"><ng-content></ng-content></div>
  </div>
</div>
