import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class MessageIdService {
  generate(): string {
    return crypto.randomUUID();
  }
}
