import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  MatButtonToggle,
  MatButtonToggleModule,
} from "@angular/material/button-toggle";
import { MatIconModule } from "@angular/material/icon";

type Appearance = "standard" | "tight";

@Component({
  selector: "cq-checkbox-button",
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule, MatIconModule],
  templateUrl: "./checkbox-button.component.html",
  styleUrls: ["./checkbox-button.component.scss"],
})
export class CheckboxButtonComponent {
  @Input() appearance: Appearance = "standard";
  @Input() checked = false;
  @Input() @HostBinding("class.disabled") disabled = false;
  @Input() value?: string;

  @HostBinding("class.cq-checkbox-button") readonly baseClass = true;

  @Output() checkedChange = new EventEmitter<boolean>();

  @ViewChild(MatButtonToggle) toggle!: MatButtonToggle;
}
