import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import {
  Injectable,
  InjectionToken,
  Injector,
  Signal,
  computed,
  effect,
  inject,
  signal,
} from "@angular/core";
import { ProgressOverlayComponent } from "../components/progress-overlay/progress-overlay.component";
import { Observable, defer, finalize, isObservable, timer } from "rxjs";

interface ProgressEvent {
  id: string;
  options?: ProgressOptions;
}

interface ProgressOptions {
  component?: ComponentType<unknown>;
  injector?: Injector;
}

interface PauseEvent {
  id: string;
}

export const PROGRESS_EVENTS = new InjectionToken<Signal<ProgressEvent[]>>(
  "CQ_PROGRESS_EVENTS",
);

@Injectable({
  providedIn: "root",
})
export class ProgressService {
  private events = signal<ProgressEvent[]>([]);
  private pauses = signal<PauseEvent[]>([]);

  isActive = computed(() => {
    const eventCount = this.events().length;
    const pauseCount = this.pauses().length;
    return pauseCount === 0 && eventCount > 0;
  });

  private overlay = inject(Overlay);
  private overlayRef?: OverlayRef;

  constructor() {
    const overlayInjector = Injector.create({
      providers: [
        { provide: PROGRESS_EVENTS, useValue: this.events.asReadonly() },
      ],
    });

    effect(() => {
      const isActive = this.isActive();

      if (!isActive) {
        this.overlayRef?.dispose();
        this.overlayRef = undefined;
      } else if (!this.overlayRef) {
        this.overlayRef = this.createOverlay();
        this.overlayRef.attach(
          new ComponentPortal(
            ProgressOverlayComponent,
            undefined,
            overlayInjector,
          ),
        );
      }
    });
  }

  forever(options?: ProgressOptions) {
    this.createEvent(options);
  }

  /**
   * Displays a progress spinner until the given observable finishes
   */
  track<T>(obs: Observable<T>, options?: ProgressOptions) {
    return defer(() => {
      const event = this.createEvent(options);
      return obs.pipe(finalize(() => this.finalizeEvent(event)));
    });
  }

  /**
   * Displays a progress spinner until the given promise finishes
   */
  resolve<T>(promise: Promise<T>, delay = 0) {
    const event = this.createEvent();
    return promise.then(
      (result) => {
        timer(delay).subscribe(() => this.finalizeEvent(event));
        return result;
      },
      (reason) => {
        timer(delay).subscribe(() => this.finalizeEvent(event));
        return Promise.reject(reason);
      },
    );
  }

  /**
   * Pauses the progress spinner until the given deferrable finishes
   */
  pause<T>(promise: Promise<T>): Promise<T>;
  pause<T>(obs: Observable<T>): Observable<T>;
  pause<T>(deferred: Promise<T> | Observable<T>) {
    if (isObservable(deferred)) {
      return defer(() => {
        const event = this.createPause();
        return deferred.pipe(finalize(() => this.finalizePause(event)));
      });
    } else {
      const event = this.createPause();
      return deferred.then(
        (result) => {
          this.finalizePause(event);
          return result;
        },
        (reason) => {
          this.finalizePause(event);
          return Promise.reject(reason);
        },
      );
    }
  }

  private createOverlay() {
    return this.overlay.create({
      height: "100vh",
      width: "100vw",
      maxHeight: "100vh",
      maxWidth: "100vw",
      hasBackdrop: true,
      backdropClass: "cq-progress-overlay-backdrop",
      panelClass: "cq-progress-overlay-pane",
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
  }

  private createEvent(options?: ProgressOptions) {
    const event: ProgressEvent = {
      id: crypto.randomUUID(),
      options,
    };
    this.events.update((existing) => {
      const updated = [...existing];
      updated.push(event);
      return updated;
    });
    return event;
  }

  private finalizeEvent(event: ProgressEvent) {
    this.events.update((existing) => {
      const updated = [...existing];
      const index = updated.indexOf(event);
      if (index >= 0) {
        updated.splice(index, 1);
      }
      return updated;
    });
  }

  private createPause() {
    const event: PauseEvent = {
      id: crypto.randomUUID(),
    };
    this.pauses.update((existing) => {
      const updated = [...existing];
      updated.push(event);
      return updated;
    });
    return event;
  }

  private finalizePause(event: PauseEvent) {
    this.pauses.update((existing) => {
      const updated = [...existing];
      const index = updated.indexOf(event);
      if (index >= 0) {
        updated.splice(index, 1);
      }
      return updated;
    });
  }
}
