import { inject, Injectable } from "@angular/core";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyPropertyNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/prop/prop-navigation.service";

export function legacyPropertyNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyPropertyNavigationService>("CQPropertyNavigation");
}

export const legacyPropertyNavigationProvider = {
  provide: LegacyPropertyNavigationService,
  useFactory: legacyPropertyNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class PropertyNavigationRouterService implements PolicyNavigationRouter {
  #legacyPropertyNavigation = inject(LegacyPropertyNavigationService);

  isPropertyActive() {
    return this.#legacyPropertyNavigation.isPropertyActive();
  }

  navigateToPropertyInformation(policyTransactionId: string) {
    return this.#legacyPropertyNavigation.navigateToPropertyInformation(
      policyTransactionId,
    );
  }

  navigateToPropertyCoverages(policyTransactionId: string) {
    return this.#legacyPropertyNavigation.navigateToPropertyCoverages(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToPropertyInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToPropertyCoverages(policyTransactionId);
  }
}
