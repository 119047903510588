import { EventEmitter, Injectable, OnDestroy, computed } from "@angular/core";
import { Router } from "@angular/router";
import { UMBRELLA } from "@cq/app/core/model/product";
import { EXACT, PARTIAL } from "@cq/app/core/routing/match-option";
import { RoutingService } from "@cq/app/core/routing/services/routing.service";
import { PolicyTransactionStoreService } from "@cq/app/policy/services/policy-transaction-store.service";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UmbrellaNavigationService implements OnDestroy {
  private destroy$ = new EventEmitter<void>();

  private transaction = computed(() => {
    return this.transactionStore
      .transactions()
      .find((transaction) => transaction.product === UMBRELLA);
  });
  private policyTransactionId = computed(() => this.transaction()?.id);
  hasUmbrella = computed(() => this.transaction() !== undefined);

  isUmbrellaActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isUmbrellaActive()),
  );
  isUmbrellaInformationActive$ = this.routing.navigationChanges$.pipe(
    map(() => this.isUmbrellaInformationActive()),
  );

  constructor(
    private readonly router: Router,
    private readonly routing: RoutingService,
    private readonly transactionStore: PolicyTransactionStoreService,
  ) {}

  isUmbrellaActive() {
    return this.router.isActive(`${this.routePrefix}/umbrella`, PARTIAL);
  }

  isUmbrellaInformationActive() {
    return this.router.isActive(
      `${this.routePrefix}/umbrella/information`,
      EXACT,
    );
  }

  navigateToUmbrellaInformation() {
    return this.router.navigate([`${this.routePrefix}/umbrella/information`]);
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
  }

  private get routePrefix() {
    return `/upgrade/policy-transaction/${this.policyTransactionId()}`;
  }
}
