import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyPropertyNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isPropertyActive() {
    return this.AEStateManager.current.isProp();
  }

  navigateToPropertyInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.prop.prop-info", {
        jobNumber,
      })
      .then(() => true);
  }

  navigateToPropertyCoverages(jobNumber: string) {
    return this.$state
      .go("ae.job.prop.prop-coverages", {
        jobNumber,
      })
      .then(() => true);
  }
}
