import { CommonModule } from "@angular/common";
import { Component, HostBinding } from "@angular/core";
import { DividerComponent } from "@cq/app/shared/structure/components/divider/divider.component";

@Component({
  selector: "cq-dialog-title-actions",
  standalone: true,
  imports: [CommonModule, DividerComponent],
  templateUrl: "./dialog-title-actions.component.html",
  styleUrls: ["./dialog-title-actions.component.scss"],
})
export class DialogTitleActionsComponent {
  @HostBinding("class.cq-dialog-title-actions") private readonly baseClass =
    true;
}
