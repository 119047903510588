import { Injectable } from "@angular/core";
import { isObservable, Observable, tap } from "rxjs";
import { Event, EventLogService } from "../event";
import { Task } from "./task";
import { Timer } from "./timer";

class TaskCancelEvent extends Event {
  constructor(task: Task, duration: number) {
    const elapsed = (duration / 1000).toFixed(3);
    super(
      "task-cancel",
      `[${task.name}] [cancel] ${task.message} (${elapsed}s)`,
      {
        task: task.serialize(),
        duration,
      },
    );
  }
}

class TaskErrorEvent extends Event {
  constructor(task: Task, duration: number) {
    const elapsed = (duration / 1000).toFixed(3);
    super(
      "task-error",
      `[${task.name}] [error] ${task.message} (${elapsed}s)`,
      {
        task: task.serialize(),
        duration,
      },
    );
  }
}

class TaskStartEvent extends Event {
  constructor(task: Task) {
    super("task-start", `[${task.name}] [start] ${task.message}`, {
      task: task.serialize(),
    });
  }
}

class TaskStopEvent extends Event {
  constructor(task: Task, duration: number) {
    const elapsed = (duration / 1000).toFixed(3);
    super("task-stop", `[${task.name}] [stop] ${task.message} (${elapsed}s)`, {
      task: task.serialize(),
      duration,
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class TaskManagerService {
  constructor(private readonly event: EventLogService) {}

  monitor<T>(task: Task, monitored: Promise<T>): Promise<T>;
  monitor<T>(task: Task, monitored: Observable<T>): Observable<T>;
  monitor<T>(task: Task, monitored: Promise<T> | Observable<T>) {
    if (isObservable(monitored)) {
      return this.observe(task, monitored);
    }
    return this.fullfill(task, monitored);
  }

  track<T>(task: Task) {
    const timer = new Timer();
    return tap<T>({
      // Log the success
      complete: () => {
        timer.stop();
        this.event.log(new TaskStopEvent(task, timer.elapsed));
      },
      // Log the rejection
      error: () => {
        timer.stop();
        this.event.log(new TaskErrorEvent(task, timer.elapsed));
      },
      // Log the starting event
      subscribe: () => {
        timer.start();
        this.event.log(new TaskStartEvent(task));
      },
      // Log the cancellation
      unsubscribe: () => {
        timer.stop();
        this.event.log(new TaskCancelEvent(task, timer.elapsed));
      },
    });
  }

  private observe<T>(task: Task, observable: Observable<T>) {
    return observable.pipe(this.track(task));
  }

  private fullfill<T>(task: Task, promise: Promise<T>) {
    const timer = new Timer();

    // Log the starting event
    timer.start();
    this.event.log(new TaskStartEvent(task));

    return promise
      .then((result) => {
        timer.stop();

        // Log the success
        this.event.log(new TaskStopEvent(task, timer.elapsed));

        return result;
      })
      .catch((rejection) => {
        timer.stop();

        // Log the rejection
        this.event.log(new TaskErrorEvent(task, timer.elapsed));

        throw rejection;
      });
  }
}
