import { computed, inject, Injectable } from "@angular/core";
import { LegacyStateStoreService } from "@cq/app/core/page/services/legacy-state-store.service";
import { Contextualizer } from "@cq/app/diagnostic/context";
import { PageStoreService } from "../../page/services/page-store.service";
import { merge } from "lodash";
import { WindowService } from "../../system/services/window.service";

@Injectable()
export class PageContextService implements Contextualizer {
  #page = inject(PageStoreService);
  #state = inject(LegacyStateStoreService);
  #window = inject(WindowService);

  context = computed(() => {
    const path = this.#window.path();
    const state = this.#state.current();
    const page = this.#page.currentId();
    const contexts = [];
    if (state) {
      contexts.push({ state });
    }
    if (page) {
      contexts.push({ page });
    }
    return merge({ path }, ...contexts);
  });
}
