import { DOCUMENT, Location } from "@angular/common";
import { Injectable, OnDestroy, computed, inject, signal } from "@angular/core";
import { EventLogService } from "@cq/app/diagnostic/event/event-log.service";
import { ApplicationVisibilityEvent } from "../../diagnostic/event";

interface WindowOrigin {
  time: number;
  navigation?: {
    type: string;
    duration: number;
  };
}

const DEFAULT_ORIGIN: WindowOrigin = {
  time: 0,
};

@Injectable({
  providedIn: "root",
})
export class WindowService implements OnDestroy {
  #document = inject(DOCUMENT);
  #event = inject(EventLogService);
  #location = inject(Location);

  #removeLocationListener = this.#location.onUrlChange(() =>
    this.updateLocation(),
  );

  private visibilityListener = () => {
    this.isHidden.set(this.#document.hidden);
    this.#event.log(new ApplicationVisibilityEvent(this.visibility()));
  };

  private isHidden = signal<boolean>(this.#document.hidden);
  readonly isVisible = computed(() => !this.isHidden());
  readonly visibility = computed(() =>
    this.isVisible() ? "visible" : "hidden",
  );

  #path = signal<string>(this.#location.path());
  path = this.#path.asReadonly();

  constructor() {
    this.addVisibilityListener();
  }

  ngOnDestroy(): void {
    this.#removeLocationListener();
    this.removeVisibilityListener();
  }

  open(url: string) {
    this.#document.defaultView?.open(url);
  }

  replace(url: string) {
    this.#document.location.assign(url);
  }

  close() {
    this.#document.defaultView?.close();
  }

  origin(): WindowOrigin {
    const view = this.#document.defaultView;
    if (!view) {
      return DEFAULT_ORIGIN;
    }
    const navigations = view.performance.getEntriesByType(
      "navigation",
    ) as PerformanceNavigationTiming[];
    const navigation =
      navigations.length > 0
        ? { type: navigations[0].type, duration: navigations[0].duration }
        : undefined;
    return {
      time: view.performance.timeOrigin,
      navigation,
    };
  }

  // Location
  updateLocation() {
    this.#path.set(this.#location.path());
  }

  // Visibility
  private addVisibilityListener() {
    this.#document.addEventListener(
      "visibilitychange",
      this.visibilityListener,
    );
  }

  private removeVisibilityListener() {
    this.#document.removeEventListener(
      "visibilitychange",
      this.visibilityListener,
    );
  }
}
