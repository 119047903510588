import { Injectable, inject } from "@angular/core";
import { DialogService } from "@cq/app/shared/dialog/services/dialog.service";
import { FatalErrorDialogComponent } from "./fatal-error-dialog/fatal-error-dialog.component";
import { throwError } from "rxjs";
import { ApplicationErrorEvent } from "../diagnostic/event";
import { EventLogService } from "@cq/app/diagnostic";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  private readonly dialog = inject(DialogService);
  private readonly event = inject(EventLogService);

  fatal(message: string, error: Error) {
    // log a diagnostic event
    this.event.log(new ApplicationErrorEvent(message, error), ["fullstory"]);

    // present the user a system exit dialog
    this.dialog
      .prompt(FatalErrorDialogComponent, {
        data: {
          message,
        },
      })
      .subscribe();

    return throwError(() => error);
  }
}
