interface User {
  racfId?: string;
}

export class Identity {
  readonly id?: string;

  constructor(user: User) {
    this.id = user.racfId?.toLowerCase();
  }
}
