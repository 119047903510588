export class Timer {
  private _started: number | null;
  private _stopped: number | null;
  private _elapsed: number | null;

  constructor() {
    this._started = null;
    this._stopped = null;
    this._elapsed = null;
  }

  start() {
    this._started = Date.now();
    this._stopped = null;
  }

  stop() {
    if (this._started !== null) {
      this._stopped = Date.now();
      this._elapsed = this._stopped - this._started;
    }
  }

  get running() {
    return this._started !== null && this._stopped === null;
  }

  get started() {
    return this._started;
  }

  get stopped() {
    return this._stopped;
  }

  get elapsed() {
    return this._elapsed ?? 0;
  }
}
