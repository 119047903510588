import { effect, inject, Injectable, Injector, signal } from "@angular/core";
import { UserStoreService } from "@cq/app/core/user/stores/user-store.service";
import { environment } from "@cq/environments/environment";
import { init, isInitialized, FullStory } from "@fullstory/browser";
import { LoggedEvent } from "../event";

const FULLSTORY_ORG = "KJMMK";

@Injectable()
export class FullstoryService {
  isEnabled = signal(environment.fullstory.isEnabled);
  isDevMode = signal(environment.fullstory.isDevMode);

  #injector = inject(Injector);
  #user = inject(UserStoreService);

  init() {
    // initialize FullStory
    init({
      orgId: FULLSTORY_ORG,
      devMode: this.isDevMode(),
    });

    // track current user to transmit user properties
    this.trackUserProperties();
  }

  dispatch(logged: LoggedEvent) {
    if (!isInitialized()) {
      return;
    }

    FullStory("trackEvent", {
      name: `ClearQuote - ${logged.event.type}`,
      properties: {
        ...logged.event.serialize(),
        context: logged.context,
      },
    });
  }

  private trackUserProperties() {
    effect(
      () => {
        const user = this.#user.current();
        if (user.isActive) {
          FullStory("setProperties", {
            type: "user",
            properties: {
              displayName: user.displayName,
              email: user.email,
              userId: user.id,
            },
          });
        }
      },
      { injector: this.#injector },
    );
  }
}
