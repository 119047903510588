import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { IconSvgComponent } from "@cq/app/shared/icon/components/icon-svg/icon-svg.component";
import { TooltipComponent } from "@cq/app/shared/tooltip/tooltip.component";

@Component({
  selector: "cq-error-tooltip",
  standalone: true,
  imports: [CommonModule, IconSvgComponent],
  templateUrl: "./error-tooltip.component.html",
  styleUrls: ["../../tooltip.component.scss"],
})
export class ErrorTooltipComponent extends TooltipComponent {}
