import { computed, Injectable, signal } from "@angular/core";
import { Identity } from "../models/identity";

@Injectable({
  providedIn: "root",
})
export class IdentityStoreService {
  private identity = signal<Identity | undefined>(undefined);
  public readonly id = computed(() => this.identity()?.id);

  set(identity: Identity) {
    this.identity.set(identity);
  }
}
