import { RouterStateSnapshot } from "@angular/router";
import { StateObject } from "@uirouter/core";

export class LegacyNavigationTarget {
  constructor(public readonly state: StateObject) {}
}

export class UpgradeNavigationTarget {
  constructor(public readonly snapshot: RouterStateSnapshot) {}
}

export type NavigationTarget = LegacyNavigationTarget | UpgradeNavigationTarget;
