// cspell:words messageid

import { HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import {
  map,
  mergeMap,
  Observable,
  of,
  OperatorFunction,
  retry,
  switchMap,
  take,
  throwError,
  timeout,
  timer,
} from "rxjs";
import { Authentication, Request, RequestorOptions } from "./requestor";
import { AuthService } from "../../auth/services/auth.service";
import { ConfigService } from "../../environment/services/config.service";
import { MessageIdService } from "./message-id.service";
import { TaskManagerService } from "@cq/app/diagnostic";

class Response<T> {
  constructor(
    private readonly _body: T,
    private readonly _headers: HttpHeaders,
  ) {}

  body(): T {
    return this._body;
  }

  headers() {
    return this._headers;
  }
}

class HttpClientResponse<T> extends Response<T> {
  constructor(response: HttpResponse<T>) {
    super(response.body as T, response.headers);
  }
}

interface Decoration {
  url: string;
  headers: HttpHeaders;
  params: HttpParams;
}

@Injectable({
  providedIn: "root",
})
export class RequestDecoratorService {
  #auth = inject(AuthService);
  #config = inject(ConfigService);
  #id = inject(MessageIdService);
  #manager = inject(TaskManagerService);

  decorate<T>(
    target: Request,
    options: RequestorOptions,
    request: (decoration: Decoration) => Observable<HttpResponse<T>>,
  ) {
    const identifier = options.identifier ?? this.#id.generate();
    return this.decorations(identifier, target, options).pipe(
      switchMap(request),
      this.timeout(options),
      this.track(identifier, target, options),
      this.retry(options),
      map((response) => new HttpClientResponse(response)),
    );
  }

  private decorations(
    identifier: string,
    target: Request,
    options: RequestorOptions,
  ): Observable<Decoration> {
    return this.headers(identifier, options).pipe(
      map((headers) => ({
        url: target.url,
        headers,
        params: this.params(options),
      })),
    );
  }

  private timeout<T>(options: RequestorOptions): OperatorFunction<T, T> {
    return (source: Observable<T>) =>
      options.timeout ? source.pipe(timeout(options.timeout)) : source;
  }

  private retry<T>(options: RequestorOptions): OperatorFunction<T, T> {
    const retryOptions = options.retry;
    return (source: Observable<T>) =>
      retryOptions
        ? source.pipe(
            retry({
              // retry the number of requested times
              count: retryOptions.times,
              delay: (error, count) => {
                // confirm the error should be retried
                if (retryOptions.retry(error)) {
                  // increase delay for each retry
                  return timer(count * retryOptions.delay);
                }
                // throw error without retrying
                return throwError(() => error);
              },
            }),
          )
        : source;
  }

  private track<T>(
    identifier: string,
    target: Request,
    options: RequestorOptions,
  ) {
    return (source: Observable<T>) =>
      options.task
        ? source.pipe(this.#manager.track(options.task(target, identifier)))
        : source;
  }

  private headers(identifier: string, options: RequestorOptions) {
    let headers = options.headers ?? new HttpHeaders();

    if (options.oauth) {
      headers = headers.set("client_id", this.#config.app.appClientKey);
    }

    if (options.identification) {
      const messageIdHeader =
        options.identification === "legacy"
          ? "x-nw-messageid"
          : "x-nw-message-id";
      headers = headers.set(messageIdHeader, identifier);
    }

    if (options.targeted) {
      headers = headers.set("x-nw-target-env", this.#config.app.targetEnv);
    }

    let result = of(headers);

    const authentication = options.authentication;
    if (authentication) {
      result = result.pipe(
        mergeMap((headers) =>
          this.setAuthorizationHeader(headers, authentication),
        ),
      );
    }

    return result;
  }

  private setAuthorizationHeader(
    headers: HttpHeaders,
    authentication: Authentication,
  ) {
    if (authentication === "identity") {
      return this.#auth.identityToken$.pipe(
        take(1),
        map((token) => headers.set("Authorization", `Bearer ${token}`)),
      );
    }

    return this.#auth.accessToken$.pipe(
      take(1),
      map((token) => headers.set("Authorization", `Bearer ${token}`)),
    );
  }

  private params(options: RequestorOptions) {
    let result = new HttpParams();
    if (options.params) {
      Object.entries(options.params).forEach(([key, value]) => {
        if (value) {
          result = result.set(key, value);
        }
      });
    }
    return result;
  }
}
