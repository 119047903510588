import { inject, Injectable } from "@angular/core";
import { PolicyNavigationRouter } from "@cq/app/policy/navigation/policy-navigation-router";
import { LegacyWorkersCompensationNavigationService } from "@legacy/nationwide/js/src/edge/quoteandbind/wc/wc-navigation.service";

export function legacyWorkersCompensationNavigationFactory(
  $injector: angular.auto.IInjectorService,
) {
  return $injector.get<LegacyWorkersCompensationNavigationService>(
    "CQWorkersCompensationNavigation",
  );
}

export const legacyWorkersCompensationNavigationProvider = {
  provide: LegacyWorkersCompensationNavigationService,
  useFactory: legacyWorkersCompensationNavigationFactory,
  deps: ["$injector"],
};

@Injectable({
  providedIn: "root",
})
export class WorkersCompensationNavigationRouterService
  implements PolicyNavigationRouter
{
  #legacyWorkersCompensationNavigation = inject(
    LegacyWorkersCompensationNavigationService,
  );

  isWorkersCompensationActive() {
    return this.#legacyWorkersCompensationNavigation.isWorkersCompensationActive();
  }

  navigateToWorkersCompensationInformation(policyTransactionId: string) {
    return this.#legacyWorkersCompensationNavigation.navigateToWorkersCompensationInformation(
      policyTransactionId,
    );
  }

  navigateToWorkersCompensationCoverages(policyTransactionId: string) {
    return this.#legacyWorkersCompensationNavigation.navigateToWorkersCompensationCoverages(
      policyTransactionId,
    );
  }

  navigateToPolicyStart(policyTransactionId: string): Promise<boolean> {
    return this.navigateToWorkersCompensationInformation(policyTransactionId);
  }

  navigateToPolicyEnd(policyTransactionId: string): Promise<boolean> {
    return this.navigateToWorkersCompensationCoverages(policyTransactionId);
  }
}
