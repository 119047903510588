import { NgModule } from "@angular/core";
import { InformationTooltipComponent } from "@cq/app/shared/tooltip/components/information-tooltip/information-tooltip.component";
import { ErrorTooltipComponent } from "./components/error-tooltip/error-tooltip.component";
import { TooltipTargetDirective } from "./directives/tooltip-target.directive";

const exposed = [
  ErrorTooltipComponent,
  InformationTooltipComponent,
  TooltipTargetDirective,
];

@NgModule({
  imports: exposed,
  exports: exposed,
})
export class TooltipModule {}
