import { NgModule } from "@angular/core";
import { InfoMessageComponent } from "./components/info-message/info-message.component";
import { WarningMessageComponent } from "./components/warning-message/warning-message.component";

const exposed = [WarningMessageComponent, InfoMessageComponent];

@NgModule({
  imports: exposed,
  exports: exposed,
})
export class MessageModule {}
