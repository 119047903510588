import { StateCode } from "@cq/app/core/location/models/state";
import { Product } from "@cq/app/core/model/product";
import { merge, omit } from "lodash";
import { DateTime } from "luxon";
import { PartialDeep } from "type-fest";

export interface CoveragePeriod {
  effective: DateTime;
  expiration: DateTime;
}

export interface PolicyGroup {
  number: string;
  effective: DateTime;
  expiration: DateTime;
}

export type PolicyTransactionType = "submission" | "renewal" | "unsupported";

export interface PolicyPeriod {
  baseState: StateCode;
  coveragePeriod: CoveragePeriod;
  policyTransactionId: string;
}

export interface PolicyTransaction {
  accountNumber: string;
  coveragePeriod?: CoveragePeriod;
  group?: PolicyGroup;
  id: string;
  isBound: boolean;
  isDiscarded: boolean;
  policyNumber: string;
  product: Product;
  type: PolicyTransactionType;
}

export type PolicyTransactionPatch =
  // product cannot be partially provided
  Omit<PartialDeep<PolicyTransaction>, "product"> & { product?: Product }; // however it may be optionally provided in whole

/*
  lodash merge does not have any awareness to frozen data types (e.g. our standard usage for Products)

  In order to make a convenient patch operation for policy transactions, we need to make sure that provided
  products are kept whole so that basic product equality checks continue to work as expected. This type
  manipulation, and accompanying special product handling are to ensure that products can be provided while
  maintaining product data.
*/
export function patchPolicyTransaction(
  transaction: PolicyTransaction,
  patch?: PolicyTransactionPatch,
) {
  const product = patch?.product ?? transaction.product;
  const original = omit(transaction, "product");
  const overrides = omit(patch, "product");
  return merge({ product }, original, overrides);
}
