import { computed, inject, Injectable } from "@angular/core";
import { WindowService } from "@cq/app/core/system/services/window.service";
import { Contextualizer } from "@cq/app/diagnostic/context";

@Injectable()
export class VisibilityContextService implements Contextualizer {
  private window = inject(WindowService);

  context = computed(() => {
    return {
      visibility: this.window.visibility(),
    };
  });
}
