import { Injectable, inject } from "@angular/core";
import { AuthService } from "../../auth/services/auth.service";
import { WindowService } from "./window.service";
import { EventLogService } from "@cq/app/diagnostic";
import { ApplicationExitEvent } from "../../diagnostic/event";

@Injectable({
  providedIn: "root",
})
export class SystemService {
  #auth = inject(AuthService);
  #event = inject(EventLogService);
  #window = inject(WindowService);

  exit() {
    this.#event.log(new ApplicationExitEvent());
    this.#auth.logoff();
    this.#window.close();
  }
}
