import { TransitionService } from "@uirouter/angularjs";
import { StateService } from "@uirouter/core";
import {
  IFormController,
  IQService,
  IPromise,
  ILogService,
  IRootScopeService,
} from "angular";

export type Validator = () => boolean | undefined;
export type ForwardNavigationEvent = () => IPromise<unknown>;

const states = {
  account: [
    "ae.account.retrieve",
    "ae.account.existing.setup",
    "ae.account.existing.information",
    "ae.account.new-account",
    "ae.account.account-information",
    "ae.account.existing.account-add-products",

    "ae.account.information",
    "ae.account.existing.questions",
    "ae.account.add-products",
  ],

  accountInformation: [
    "ae.account.existing.setup",
    "ae.account.new-account",
    "ae.account.information",
  ],

  accountQuestions: [
    "ae.account.existing.information",
    "ae.account.account-information",
    "ae.account.existing.questions",
  ],

  accountAddProduct: ["ae.account.add-products"],

  bop: ["ae.job.bop", "ae.job.bop.bop-info", "ae.job.bop.bop-coverages"],
  bopInfo: ["ae.job.bop.bop-info"],
  bopCoverages: ["ae.job.bop.bop-coverages"],

  gl: ["ae.job.gl", "ae.job.gl.gl-info", "ae.job.gl.gl-coverages"],
  glInfo: ["ae.job.gl.gl-info"],
  glCoverages: ["ae.job.gl.gl-coverages"],
  glNoSubLine: ["ae.job.gl.no-subline"],

  farm: ["ae.job.farm", "ae.job.farm.farm-info", "ae.job.farm.farm-coverages"],
  farmInfo: ["ae.job.farm.farm-info"],
  farmCoverages: ["ae.job.farm.farm-coverages"],

  prop: ["ae.job.prop", "ae.job.prop.prop-info", "ae.job.prop.prop-coverages"],
  propInfo: ["ae.job.prop.prop-info"],
  propCoverages: ["ae.job.prop.prop-coverages"],

  auto: ["ae.job.auto", "ae.job.auto.auto-info", "ae.job.auto.auto-coverages"],
  autoInfo: ["ae.job.auto.auto-info"],
  autoCoverages: ["ae.job.auto.auto-coverages"],
  wc: ["ae.job.wc", "ae.job.wc.wc-info", "ae.job.wc.coverages"],
  wcInfo: ["ae.job.wc.wc-info"],
  wcCoverages: ["ae.job.wc.coverages"],

  im: ["ae.job.im", "ae.job.im.im-info", "ae.job.im.im-coverages"],
  imInfo: ["ae.job.im.im-info"],
  imCoverages: ["ae.job.im.im-coverages"],

  umbrella: ["ae.job.umb.umb-info"],

  loss: ["ae.account.existing.loss"],
  review: [
    "ae.account.review",
    "ae.account.irpm",
    "ae.account.additional-information",
  ],

  // Trigger darker sub page theme
  subPage: [
    "ae.account.additional-information",
    "ae.account.irpm",
    "ae.job.auto.registered-vehicle-search",
    "ae.job.auto.vehicle",
    "ae.job.bop.building",
    "ae.job.wc.location",
    "ae.job.gl.exposure",
    "ae.job.farm.residence",
    "ae.job.farm.structure",
    "ae.job.farm.personal-property",
    "ae.job.prop.building",
    "ae.job.prop.business-income",
    "ae.job.prop.personal-property",
    "ae.job.prop.special-class",
    "ae.job.prop.special-class-business-income",
    "ae.job.im.contractors-equipment",
    "ae.job.im.contractors-equipment-item",
    "ae.job.im.contractors-advantage",
    "ae.job.im.farm-machinery-blanket",
    "ae.job.im.farm-machinery-sched",
    "ae.job.im.farm-machinery-sched-item",
    "ae.job.im.builders-risk",
    "ae.job.im.builders-risk-building",
    "ae.job.im.installation-floater",
    "ae.job.im.installation-floater-building",
    "ae.job.im.dry-cleaner-program",
    "ae.job.im.dry-cleaner-building",
  ],

  // Disable navigation menu usage
  navDisabled: [
    "ae.account.additional-information",
    "ae.job.auto.registered-vehicle-search",
  ],
  contextMenuDisabled: ["ae.job.gl.no-subline"],

  // First Secondary Navigation Tabs
  firstSecondaryNavigation: [
    "ae.job.auto.vehicle.*.details",
    "ae.job.bop.building.*.address",
  ],

  // Last Secondary Navigation Tabs
  lastSecondaryNavigation: [
    "ae.account.existing.information",
    "ae.account.account-information",
    "ae.account.existing.questions",
    "ae.job.bop.bop-coverages",
    "ae.job.wc.coverages",
    "ae.job.auto.auto-coverages",
  ],

  noPrimaryNavigation: ["ae.account.retrieve", "ae.upgrade.mockup"],

  // No Secondary Navigation
  noSecondaryNavigation: [
    "ae.job.auto.registered-vehicle-search",
    "ae.account.retrieve",
    "ae.account.review",
    "ae.job.gl.no-subline",
    "ae.upgrade.*",
  ],
};

export const STATES = Object.freeze(states);

class State {
  static in(states: string[], state: StateService): boolean {
    return states.reduce(
      (included: boolean, stateName: string) =>
        included || state.includes(stateName),
      false,
    );
  }

  static isAccount(state: StateService): boolean {
    return this.in(states.account, state);
  }

  static isAccountInformation(state: StateService): boolean {
    return this.in(states.accountInformation, state);
  }

  static isAccountQuestions(state: StateService): boolean {
    return this.in(states.accountQuestions, state);
  }

  static isAccountAddProduct(state: StateService): boolean {
    return this.in(states.accountAddProduct, state);
  }

  static isBOP(state: StateService): boolean {
    return this.in(states.bop, state);
  }

  static isBOPInfo(state: StateService): boolean {
    return this.in(states.bopInfo, state);
  }

  static isBOPCoverages(state: StateService): boolean {
    return this.in(states.bopCoverages, state);
  }

  static isGL(state: StateService): boolean {
    return this.in(states.gl, state);
  }

  static isGLInfo(state: StateService): boolean {
    return this.in(states.glInfo, state);
  }

  static isGLCoverages(state: StateService): boolean {
    return this.in(states.glCoverages, state);
  }

  static isFarm(state: StateService): boolean {
    return this.in(states.farm, state);
  }

  static isFarmInfo(state: StateService): boolean {
    return this.in(states.farmInfo, state);
  }

  static isFarmCoverages(state: StateService): boolean {
    return this.in(states.farmCoverages, state);
  }

  static isProp(state: StateService): boolean {
    return this.in(states.prop, state);
  }

  static isPropInfo(state: StateService): boolean {
    return this.in(states.propInfo, state);
  }

  static isPropCoverages(state: StateService): boolean {
    return this.in(states.propCoverages, state);
  }

  static isAuto(state: StateService): boolean {
    return this.in(states.auto, state);
  }

  static isAutoInfo(state: StateService): boolean {
    return this.in(states.autoInfo, state);
  }

  static isAutoCoverages(state: StateService): boolean {
    return this.in(states.autoCoverages, state);
  }

  static isWC(state: StateService): boolean {
    return this.in(states.wc, state);
  }

  static isWCInfo(state: StateService): boolean {
    return this.in(states.wcInfo, state);
  }

  static isWCCoverages(state: StateService): boolean {
    return this.in(states.wcCoverages, state);
  }

  static isIM(state: StateService) {
    return this.in(states.im, state);
  }

  static isIMInfo(state: StateService) {
    return this.in(states.imInfo, state);
  }

  static isIMCoverages(state: StateService) {
    return this.in(states.imCoverages, state);
  }

  static isUmbrella(state: StateService): boolean {
    return this.in(states.umbrella, state);
  }

  static isNoSubLine(state: StateService): boolean {
    return this.in(states.glNoSubLine, state);
  }

  static isLoss(state: StateService): boolean {
    return this.in(states.loss, state);
  }

  static isReview(state: StateService): boolean {
    return this.in(states.review, state);
  }

  static isSubPage(state: StateService): boolean {
    return this.in(states.subPage, state);
  }

  static isNavDisabled(state: StateService): boolean {
    return this.in(states.navDisabled, state);
  }

  static isContextMenuDisabled(state: StateService): boolean {
    return this.in(states.contextMenuDisabled, state);
  }

  static isFirstSecondaryNavigation(state: StateService): boolean {
    return this.in(states.firstSecondaryNavigation, state);
  }

  static isLastSecondaryNavigation(state: StateService): boolean {
    return this.in(states.lastSecondaryNavigation, state);
  }

  static noPrimaryNavigation(state: StateService): boolean {
    return this.in(states.noPrimaryNavigation, state);
  }

  static noSecondaryNavigation(state: StateService): boolean {
    return this.in(states.noSecondaryNavigation, state);
  }

  static isJob(state: StateService): boolean {
    return this.in(["ae.job"], state);
  }
}

export class CurrentState {
  public mainForm: IFormController;
  $q: IQService;
  $state: StateService;
  rootScope: IRootScopeService;
  validators!: Validator[];
  forwardNavigationEvents!: ForwardNavigationEvent[];

  constructor(
    $state: StateService,
    $q: IQService,
    $rootScope: IRootScopeService,
  ) {
    this.$state = $state;
    this.$q = $q;
    this.mainForm = null as any;
    this.rootScope = $rootScope;
    this.reset();
  }

  isAccount() {
    return State.isAccount(this.$state);
  }

  isAccountInformation() {
    return State.isAccountInformation(this.$state);
  }

  isAccountQuestions() {
    return State.isAccountQuestions(this.$state);
  }

  isAccountAddProduct() {
    return State.isAccountAddProduct(this.$state);
  }

  isBOP() {
    return State.isBOP(this.$state);
  }

  isBOPInfo() {
    return State.isBOPInfo(this.$state);
  }

  isBOPCoverages() {
    return State.isBOPCoverages(this.$state);
  }

  isGL() {
    return State.isGL(this.$state);
  }

  isGLInfo() {
    return State.isGLInfo(this.$state);
  }

  isGLCoverages() {
    return State.isGLCoverages(this.$state);
  }

  isFarm() {
    return State.isFarm(this.$state);
  }

  isFarmInfo() {
    return State.isFarmInfo(this.$state);
  }

  isFarmCoverages() {
    return State.isFarmCoverages(this.$state);
  }

  isProp() {
    return State.isProp(this.$state);
  }

  isPropInfo() {
    return State.isPropInfo(this.$state);
  }

  isPropCoverages() {
    return State.isPropCoverages(this.$state);
  }

  isAuto() {
    return State.isAuto(this.$state);
  }

  isAutoInfo() {
    return State.isAutoInfo(this.$state);
  }

  isAutoCoverages() {
    return State.isAutoCoverages(this.$state);
  }

  isWC() {
    return State.isWC(this.$state);
  }

  isWCInfo() {
    return State.isWCInfo(this.$state);
  }

  isWCCoverages() {
    return State.isWCCoverages(this.$state);
  }

  isIm() {
    return State.isIM(this.$state);
  }

  isImInfo() {
    return State.isIMInfo(this.$state);
  }

  isImCoverages() {
    return State.isIMCoverages(this.$state);
  }

  isUmbrella() {
    return State.isUmbrella(this.$state);
  }

  isLoss() {
    return State.isLoss(this.$state);
  }

  isReview() {
    return State.isReview(this.$state);
  }

  isSubPage() {
    return State.isSubPage(this.$state);
  }

  isNavDisabled() {
    return State.isNavDisabled(this.$state);
  }

  isContextMenuDisabled() {
    return State.isContextMenuDisabled(this.$state);
  }

  isFirstSecondaryNavigation() {
    return State.isFirstSecondaryNavigation(this.$state);
  }

  isLastSecondaryNavigation() {
    return State.isLastSecondaryNavigation(this.$state);
  }

  hasNoPrimaryNavigation() {
    return State.noPrimaryNavigation(this.$state);
  }

  hasNoSecondaryNavigation() {
    return State.noSecondaryNavigation(this.$state);
  }

  reset() {
    this.validators = [];
    this.forwardNavigationEvents = [];
    if (this.mainForm) {
      this.mainForm.$setPristine();
    }
  }

  reload() {
    return this.$state.go(
      this.$state.current,
      {
        flow: false,
        navbar: false,
        bypassValidation: true,
      },
      {
        reload: true,
      },
    );
  }

  addValidator(validator: Validator) {
    this.validators.push(validator);
  }

  addForwardNavigationEvent(event: ForwardNavigationEvent) {
    this.forwardNavigationEvents.push(event);
  }

  allowForwardNavigation() {
    let valid: boolean | undefined = true;

    for (const validator of this.validators) {
      valid = validator();

      if (!valid) {
        break;
      }
    }

    return valid ?? false;
  }

  onForwardNavigation(): IPromise<unknown> {
    let chain: IPromise<unknown> = Promise.resolve(true);
    this.forwardNavigationEvents.forEach((p) => {
      chain = chain.then(p);
    });
    return chain;
  }

  isAdd(): boolean {
    throw new Error("Method not implemented.");
  }
}

class StateTracker {
  metrics: Map<any, any>;
  visited;

  constructor() {
    this.visited = {
      account: false,
      bop: false,
      gl: false,
      auto: false,
      wc: false,
      farm: false,
      prop: false,
      loss: false,
      review: false,
      jobs: new Set(),
    };

    this.metrics = new Map();
  }

  visit(state: StateService) {
    this.visited.account ||= State.isAccount(state);
    this.visited.bop ||= State.isBOP(state);
    this.visited.gl ||= State.isGL(state);
    this.visited.farm ||= State.isFarm(state);
    this.visited.prop ||= State.isProp(state);
    this.visited.auto ||= State.isAuto(state);
    this.visited.wc ||= State.isWC(state);
    this.visited.loss ||= State.isLoss(state);
    this.visited.review ||= State.isReview(state);

    if (State.isJob(state)) {
      this.visited.jobs.add(state.params.jobNumber);
    }

    // Track state metrics
    let metrics = this.metrics.get(state.current);
    if (metrics) {
      // Bump the visit count
      metrics.count++;
    } else {
      // Initialize the metrics for the state
      metrics = {
        count: 1,
      };
      this.metrics.set(state.current, metrics);
    }
  }

  count(state: StateService) {
    const metrics = this.metrics.get(state.current);
    if (metrics) {
      return metrics.count as number;
    }

    return 0;
  }

  jobVisited(jobNumber: string) {
    return this.visited.jobs.has(jobNumber);
  }
}

export default class StateManager {
  public readonly current: CurrentState;
  isDisableAllTabs: boolean;
  isBOPBound!: boolean;
  isWCBound!: boolean;
  isCABound!: boolean;
  isCUIssued!: boolean;
  isGLIssued!: boolean;
  isFarmBound!: boolean;
  isPropBound!: boolean;
  isIMBound!: boolean;
  tracker?: StateTracker;
  $log: ILogService;
  $state: StateService;
  transitions: any[];
  isUmbBound!: boolean;
  isGLBound!: boolean;

  /* @ngInject */
  constructor(
    $log: ILogService,
    $q: IQService,
    $state: StateService,
    $transitions: TransitionService,
    $rootScope: IRootScopeService,
  ) {
    this.$log = $log;
    this.$state = $state;

    this.current = new CurrentState($state, $q, $rootScope);
    this.tracker = new StateTracker();
    this.transitions = [];

    this.isDisableAllTabs = false;

    $transitions.onStart({}, (transition) => {
      this.$log.info("$transitions.onStart");

      const now = new Date().getTime(),
        from = transition.from(),
        to = transition.to();

      this.transitions.push({
        id: transition.$id,
        from: from.name,
        to: to.name,
        start: now,
      });
    });

    $transitions.onFinish({}, (transition) => {
      // Reset the current state
      this.current.reset();

      const now = new Date().getTime(),
        tracked = this.transitions.find((t) => t.id === transition.$id);

      tracked.end = now;
      tracked.duration = tracked.end - tracked.start;

      const elapsed = (tracked.duration / 1000).toFixed(3);

      this.$log.info(`$transitions.onFinish: ${tracked.to} - ${elapsed}s`);
    });

    $transitions.onEnter({}, (transition: any, state: any) => {
      this.$log.info(`$transitions.onEnter: ${state.name}`);
    });

    $transitions.onSuccess({}, () => {
      this.$log.info(`$transitions.onSuccess: ${$state.current.name}`);
      this.tracker?.visit($state);
    });
  }
}
