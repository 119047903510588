import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { TimeoutError, map } from "rxjs";
import { Task } from "@cq/app/diagnostic";
import { RequestDecoratorService } from "./request-decorator.service";

const url = (...paths: (string | number | undefined)[]) =>
  paths.filter((p) => p !== undefined).join("/");

type Params = {
  [param: string]: string | number | boolean | undefined;
};

export type Authentication = "auth" | "identity";
type Identification = "legacy" | "standard";

type RequestError = HttpErrorResponse | TimeoutError;

const RETRY_DEFAULTS: RetryOptions = {
  times: 3,
  delay: 250,
  retry: () => true,
};

interface RetryOptions {
  times: number;
  delay: number;
  retry: (response: RequestError) => boolean;
}

export interface Request {
  method: string;
  url: string;
}

type TaskGenerator = (request: Request, identifier: string) => Task;

export interface RequestorOptions {
  authentication?: Authentication;
  headers?: HttpHeaders;
  identification?: Identification;
  identifier?: string;
  oauth?: boolean;
  params?: Params;
  retry?: RetryOptions;
  root: string;
  targeted?: boolean;
  timeout?: number;
  task?: TaskGenerator;
}

class BodyRequestor {
  constructor(private readonly requestor: Requestor) {}

  get<T>(path?: string | number) {
    return this.requestor.get<T>(path).pipe(map((response) => response.body()));
  }

  patch<T>(path: string | number, data: unknown) {
    return this.requestor
      .patch<T>(path, data)
      .pipe(map((response) => response.body()));
  }

  post<T>(path: string | number, data: unknown) {
    return this.requestor
      .post<T>(path, data)
      .pipe(map((response) => response.body()));
  }
}

interface JsonRPCResponse<T> {
  id: string;
  result: T;
  jsonrpc: string;
}

class JsonRPCRequestor {
  constructor(private readonly requestor: Requestor) {}

  post<T>(
    path: string | number,
    method: string,
    id: string,
    data: unknown = [],
  ) {
    const params = Array.isArray(data) ? data : [data];
    return this.requestor
      .post<JsonRPCResponse<T>>(path, {
        id,
        jsonrpc: "2.0",
        method,
        params,
      })
      .pipe(map((response) => response.body().result));
  }
}

export class Requestor {
  constructor(
    private readonly decorator: RequestDecoratorService,
    private readonly http: HttpClient,
    private readonly options: RequestorOptions,
  ) {}

  get<T>(path?: string | number) {
    const target: Request = {
      method: "GET",
      url: url(this.options.root, path),
    };
    return this.decorator.decorate(target, this.options, (decoration) =>
      this.http.get<T>(decoration.url, {
        headers: decoration.headers,
        params: decoration.params,
        observe: "response",
      }),
    );
  }

  patch<T>(path: string | number, data: unknown) {
    const target: Request = {
      method: "PATCH",
      url: url(this.options.root, path),
    };
    return this.decorator.decorate(target, this.options, (decoration) =>
      this.http.patch<T>(decoration.url, data, {
        headers: decoration.headers,
        params: decoration.params,
        observe: "response",
      }),
    );
  }

  post<T>(path: string | number, data: unknown) {
    const target: Request = {
      method: "POST",
      url: url(this.options.root, path),
    };
    return this.decorator.decorate(target, this.options, (decoration) =>
      this.http.post<T>(decoration.url, data, {
        headers: decoration.headers,
        params: decoration.params,
        observe: "response",
      }),
    );
  }

  authenticated(authentication: Authentication = "auth") {
    return this.requestor({ ...this.options, authentication });
  }

  identified(identification: Identification = "standard") {
    return this.requestor({ ...this.options, identification });
  }

  identifier(identifier: string) {
    return this.requestor({ ...this.options, identifier });
  }

  headers(_headers: HttpHeaders, append = false) {
    let headers = _headers;

    if (append) {
      const existing = this.options.headers ?? new HttpHeaders();
      headers = _headers
        .keys()
        .reduce(
          (result, key) => result.append(key, _headers.get(key) as string),
          existing,
        );
    }

    return this.requestor({ ...this.options, headers });
  }

  oauth(oauth = true) {
    return this.requestor({ ...this.options, oauth });
  }

  retry(options?: Partial<RetryOptions>) {
    const retry = { ...RETRY_DEFAULTS, ...options };
    return this.requestor({ ...this.options, retry });
  }

  targeted(targeted = true) {
    return this.requestor({ ...this.options, targeted });
  }

  task(task: TaskGenerator) {
    return this.requestor({ ...this.options, task });
  }

  timeout(timeout: number) {
    return this.requestor({ ...this.options, timeout });
  }

  params(params: Params) {
    return this.requestor({ ...this.options, params });
  }

  private requestor(options: RequestorOptions) {
    return new Requestor(this.decorator, this.http, options);
  }

  body() {
    return new BodyRequestor(this);
  }

  jsonrpc() {
    return new JsonRPCRequestor(this);
  }
}
