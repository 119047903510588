import { StateService } from "@uirouter/core";
import StateManager from "../common/navigation/state-manager.service";

export class LegacyWorkersCompensationNavigationService {
  /* @ngInject */
  constructor(
    private readonly $state: StateService,
    private readonly AEStateManager: StateManager,
  ) {}

  isWorkersCompensationActive() {
    return this.AEStateManager.current.isWC();
  }

  navigateToWorkersCompensationInformation(jobNumber: string) {
    return this.$state
      .go("ae.job.wc.wc-info", {
        jobNumber,
      })
      .then(() => true);
  }

  navigateToWorkersCompensationCoverages(jobNumber: string) {
    return this.$state
      .go("ae.job.wc.coverages", {
        jobNumber,
      })
      .then(() => true);
  }
}
