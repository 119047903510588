<button
  mat-raised-button
  [attr.aria-label]="label ?? null"
  [class.tight-button]="appearance === 'tight'"
  [disabled]="disabled"
>
  <span class="button-content">
    <ng-content></ng-content>
  </span>
</button>
