import { Component, computed, inject } from "@angular/core";
import { ButtonModule } from "@cq/app/shared/button/button.module";
import { DialogModule } from "@cq/app/shared/dialog/dialog.module";
import { RequiredDataDialog } from "@cq/app/shared/dialog/model/dialog";
import { IconModule } from "@cq/app/shared/icon/icon.module";
import { UserStoreService } from "../../user/stores/user-store.service";
import { SystemService } from "../../system/services/system.service";

interface Data {
  message: string;
}

@Component({
  selector: "cq-fatal-error-dialog",
  standalone: true,
  imports: [ButtonModule, DialogModule, IconModule],
  templateUrl: "./fatal-error-dialog.component.html",
  styleUrl: "./fatal-error-dialog.component.scss",
})
export class FatalErrorDialogComponent extends RequiredDataDialog<
  FatalErrorDialogComponent,
  void,
  Data
> {
  private readonly system = inject(SystemService);
  private readonly user = inject(UserStoreService);

  helpDeskNumber = computed(() => this.user.current().helpDeskNumber);

  exit() {
    this.system.exit();
  }
}
